/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_URLS from "../../../config/routes";
import HistoryPlayer from "../../CustomHistoryPlayer/HistoryPlayer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../config/store";
import { getAllChannel, getChannelsRecordings } from "../middleware/channel";
import { useEffect, useState } from "react";
import {
  dateWithMonthFormat,
  timeOnlyFormat,
} from "../../../helper/dateFormate";
import { setVideoOnDemandUrl } from "../../BroadcastManagement/slices/broadcastSlice";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import LocalstorageService from "../../../helper/localstorage-services";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const ChannelHistory = () => {
  const { channel_id, vod_src } = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { channelDetail, channelRecordings, channelDetailLoading } =
    useSelector((state: RootState) => state.channel);
  const { sidebarToggle } = useSelector((state: RootState) => state.users);
  const [date, setDate] = useState<String>("");
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const userData = LocalstorageService.getLoggedInUserDetails();
  const themeData = LocalstorageService.getThemeMode();

  useEffect(() => {
    dispatch(getAllChannel(userData._id));
  }, []);

  const singleChannel = channelDetail.filter((item) => item._id === channel_id);

  useEffect(() => {
    if (!channelDetailLoading && initialLoading) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line
  }, [channelDetail]);

  useEffect(() => {
    singleChannel[0]?.bucketName &&
      dispatch(getChannelsRecordings(singleChannel[0]?.bucketName));
  }, [singleChannel[0]?.bucketName]);

  const datePart = date?.split("-");
  let modifiedDate = "";
  // if (datePart.length > 1) {
  //   modifiedDate = `${datePart[0]}/${datePart[1]}/${parseInt(datePart[2], 10)}`;
  // }
  if (datePart.length > 1) {
    const month = parseInt(datePart[1], 10);
    modifiedDate = `${datePart[0]}/${month}/${parseInt(datePart[2], 10)}/`;
  }

  const filteredData = channelRecordings?.filter((item) => {
    const filteredDate = modifiedDate.toLowerCase();
    return item?.toLowerCase().includes(filteredDate);
  });

  // console.log("Initial: ", channelRecordings);
  // console.log("After ", filteredData);

  interface TimestampMap {
    [date: string]: string[];
  }

  const timestampMap: TimestampMap = {};
  filteredData?.forEach((item) => {
    const parts = item.split("/");
    const date = parts.slice(4, 7).join("/");

    if (!timestampMap[date]) {
      timestampMap[date] = [];
    }
    timestampMap[date].push(item);
  });

  const setVodUrl = (url: string) => {
    const parts = url.split("/");
    dispatch(
      setVideoOnDemandUrl(
        `https://${singleChannel[0]?.distributionDomainName}/${url}`
      )
    );
    navigate(`${ROUTE_URLS.CHANNEL_HISTORY}/${channel_id}/${parts[9]}`);
  };

  const getTimeFromUrl = (url: string): string | null => {
    const parts = url.split("/");
    if (parts.length >= 12) {
      const year = parts[4].padStart(2, "0");
      const month = parts[5].padStart(2, "0");
      const day = parts[6].padStart(2, "0");
      const hour = parts[7].padStart(2, "0");
      const minute = parts[8].padStart(2, "0");
      return (
        dateWithMonthFormat(
          `${year}-${month}-${day}T${hour}:${minute}:00.000+00:00`
        ) +
        timeOnlyFormat(`${year}-${month}-${day}T${hour}:${minute}:00.000+00:00`)
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    timestampMap[Object.keys(timestampMap)?.[0]]?.[0] &&
      dispatch(
        setVideoOnDemandUrl(
          `https://${singleChannel[0]?.distributionDomainName}/${timestampMap[
            Object.keys(timestampMap)?.[0]
          ]?.[0]}`
        )
      );
    const parts = timestampMap[Object.keys(timestampMap)?.[0]]?.[0].split("/");
    parts &&
      navigate(`${ROUTE_URLS.CHANNEL_HISTORY}/${channel_id}/${parts[9]}`);
  }, [timestampMap[Object.keys(timestampMap)?.[0]]?.[0]]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/channel">
      Channels
    </Link>,
    <Typography key="2" color="text.primary">
      Recordings
    </Typography>,
  ];

  return (
    <div>
      <div className="mainPageHeading">
        <h1
          style={{
            color: themeData === "dark" ? "white" : "",
            marginLeft: sidebarToggle ? "180px" : "",
          }}
          className={`${
            themeData === "light" ? "login-text-dark" : ""
          } pageHeading`}
        >
          Channel Recordings
        </h1>
        <Stack spacing={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </div>
      {channelDetailLoading && initialLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              md: "row",
            },
            marginTop: { xs: "-30px", md: "0px" },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "20%",
              },
              paddingTop: "20px",
            }}
            className="historyMenu"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: "8px 0px",
              }}
            >
              <input
                type="date"
                name="selectedDate"
                id="selectedDate"
                min="2023-01-01"
                onChange={(date) => {
                  setDate(date.target.value);
                }}
                style={{
                  width: "70%",
                  height: "18px",
                  padding: "5px",
                  fontSize: "20px",
                  border: "1px solid #6d3190",
                  borderRadius: "5px 0px 0px 5px",
                  color: themeData === "dark" ? "white" : "black",
                }}
              />
              <button
                style={{
                  width: "28%",
                  height: "30px",
                  fontSize: "20px",
                  border: "1px solid #6d3190",
                  borderRadius: "0px 5px 5px 0px",
                }}
                onClick={() => {
                  setDate("");
                }}
              >
                Clear
              </button>
            </div>
            <Box
              sx={{
                height: { xs: "100%", md: "80vh" },
                overflowY: "scroll",
                paddingRight: "1px",
              }}
            >
              <div>
                {Object.entries(timestampMap).map(([channelRef, items]) => (
                  <div
                    style={{
                      maxHeight: "250px",
                      overflowY: "scroll",
                      marginBottom: "5px",
                    }}
                    key={channelRef}
                  >
                    <h4
                      style={{
                        position: "sticky",
                        marginTop: "0",
                        top: "6px",
                        backgroundColor: "white",
                        padding: "5px",
                        color: "#6d3190",
                      }}
                    >
                      {dateWithMonthFormat(channelRef)}
                    </h4>
                    {items.map((item) => (
                      <p
                        {...(vod_src === item.split("/")?.[9]
                          ? {
                              style: {
                                background: "#6d3190",
                                color: "white",
                                paddingLeft: "15px",
                              },
                            }
                          : {
                              style: {
                                color: themeData === "dark" ? "white" : "black",
                              },
                            })}
                        onClick={() => {
                          setVodUrl(item);
                        }}
                        key={item}
                      >
                        {getTimeFromUrl(item)}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
              {filteredData.length === 0 && (
                <p style={{ margin: "40px 5px", color: "#ea1a1a" }}>
                  No recordings found
                </p>
              )}
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "80%" } }}>
            <HistoryPlayer />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ChannelHistory;
