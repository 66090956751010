import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../config/store";
import { resetUserDetails } from "../../UsersManagement/slices/userSlice";
import { getAllUserDetails } from "../../UsersManagement/middleware/users";
import swap from "../../../assets/images/change_user.png";
import deleteBox from "../../../assets/images/trash.svg";
import {
  adminGetChannels,
  updateChannelDetail,
} from "../../Channel/middleware/channel";
import ToastService from "../../../helper/toast-services";
import LocalstorageService from "../../../helper/localstorage-services";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const ManageChannel = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const { userLoading, userDetails } = useSelector(
    (state: RootState) => state.users
  );
  const {
    updateChannelLoading,
    updateChannelError,
    updateChannelMessage,
    channelDetailLoading,
    channelDetail,
  } = useSelector((state: RootState) => state.channel);
  const { sidebarToggle } = useSelector((state: RootState) => state.users);

  const [isOpenAdd, setIsOpenAdd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userID, setUserID] = useState<string>("");
  const [channelID, setChannelID] = useState<string>("");
  const themeData = LocalstorageService.getThemeMode();

  useEffect(() => {
    if (!userLoading && !channelDetailLoading) {
      setLoading(false);
    }
  }, [userDetails, channelDetail]);

  useEffect(() => {
    dispatch(resetUserDetails());
    dispatch(getAllUserDetails({ page: 1, limit: 10000000 }));
    dispatch(adminGetChannels());
    // eslint-disable-next-line
  }, [updateChannelMessage]);

  useEffect(() => {
    if (updateChannelMessage) {
      ToastService.success(updateChannelMessage);
      handleCloseAddModal();
    }
  }, [updateChannelMessage]);

  const handleCloseAddModal = () => {
    setIsOpenAdd(false);
  };

  const swapUser = (channelId: string, userId: string) => {
    setChannelID(channelId);
    setUserID(userId);
    setIsOpenAdd(true);
  };

  const changeUserSubmit = (event: any) => {
    event.preventDefault();
    // const form = event.target;
    // const userId = form.userId?.value;
    const changeUserValue: any = {
      userId: userID,
    };
    dispatch(updateChannelDetail(channelID, changeUserValue));
  };

  return (
    <div>
      <div className="mainPageHeading">
        <h1
          style={{
            color: themeData === "dark" ? "white" : "",
            marginLeft: sidebarToggle ? "180px" : "",
          }}
          className="pageHeading"
        >
          Manage Channels
        </h1>
      </div>
      {loading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 100 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Serial
                </TableCell>
                <TableCell align="left" padding="normal">
                  Channel Name
                </TableCell>
                <TableCell align="left" padding="normal">
                  User Name
                </TableCell>
                <TableCell align="left" padding="normal">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {channelDetail?.map((row, index) => {
                const user = userDetails?.data?.find(
                  (user) => user._id === row.userId
                );
                const fullName = user
                  ? `${user.firstName} ${user.lastName}`
                  : "- - - - -";
                return (
                  <TableRow
                    hover
                    key={row._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "default",
                    }}
                  >
                    <TableCell align="left" padding="normal">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {row?.channelReference}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {fullName}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                        }}
                      >
                        <Tooltip
                          title="Change user"
                          placement="left"
                          style={{ cursor: "pointer" }}
                        >
                          <ManageAccountsIcon
                            onClick={() => {
                              swapUser(row?._id, row?.userId);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <img
                            style={{ cursor: "pointer", width: "22px" }}
                            onClick={() => console.log("deleteobject")}
                            src={deleteBox}
                            alt="delete"
                          />
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        fullWidth
        open={isOpenAdd}
        onClose={handleCloseAddModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="modalHead">
            <span>Change User</span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleCloseAddModal}
            />
          </div>
        </DialogTitle>

        <>
          <form onSubmit={changeUserSubmit}>
            <DialogContent>
              {updateChannelError && (
                <Alert severity="error">{updateChannelError}</Alert>
              )}
              <FormControl style={{ marginTop: "20px" }} fullWidth>
                <InputLabel id="demo-simple-select-error-label">
                  User Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="userID"
                  label="Channel Type"
                  value={userID}
                  name="userID"
                  placeholder="Select Channel Type"
                  onChange={(event) => {
                    setUserID(event?.target?.value);
                  }}
                >
                  {userDetails?.data?.map((item) => (
                    <MenuItem key={item?._id} value={item?._id}>
                      {item?.firstName + " " + item?.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions
              style={{ marginBottom: "20px", justifyContent: "center" }}
            >
              <Button
                color="primary"
                variant="outlined"
                sx={{
                  borderColor: `${
                    theme.palette.mode === "dark"
                      ? "primary.light"
                      : "primary.main"
                  }`,
                  color: `${
                    theme.palette.mode === "dark"
                      ? "primary.light"
                      : "primary.main"
                  }`,
                  "&:hover": {
                    borderColor: `${
                      theme.palette.mode === "dark"
                        ? "primary.dark"
                        : "primary.main"
                    }`,
                    color: `${
                      theme.palette.mode === "dark"
                        ? "primary.dark"
                        : "primary.main"
                    }`,
                  },
                }}
                type="button"
                size="large"
                style={{ width: "270px" }}
                className="loginButton"
                disabled={updateChannelLoading}
                onClick={handleCloseAddModal}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{ width: "270px" }}
                type="submit"
                size="large"
                disabled={updateChannelLoading}
                className="loginButton"
              >
                Confirm
              </Button>
            </DialogActions>
          </form>
        </>
      </Dialog>
    </div>
  );
};

export default ManageChannel;
