/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import ROUTE_URLS from "../../../config/routes";
import HistoryPlayer from "../../CustomHistoryPlayer/HistoryPlayer";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../config/store";
import { useEffect, useState } from "react";
import {
  getBroadcastRecordings,
  watchLiveStream,
} from "../middleware/broadcast";
import {
  dateWithMonthFormat,
  timeOnlyFormat,
} from "../../../helper/dateFormate";
import {
  recordingSchema,
  setVideoOnDemandUrl,
  setbucketNames,
} from "../slices/broadcastSlice";
import LocalstorageService from "../../../helper/localstorage-services";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { get } from "../../../helper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const BroadcastHistory = () => {
  const { broadcast_id, vod_src } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { broadRecordings, bucketNames, broadcastLiveStream } = useSelector(
    (state: RootState) => state.broadcast
  );
  const { sidebarToggle } = useSelector((state: RootState) => state.users);
  const navigate = useNavigate();
  // const [date, setDate] = useState<String>("");
  const [loading, setLoading] = useState<boolean>(true);
  const themeData = LocalstorageService.getThemeMode();
  const [responses, setResponses] = useState<
    Array<{ bucketName: string; data: string[]; url: string }>
  >([]);

  useEffect(() => {
    dispatch(getBroadcastRecordings(`broadcastId=${broadcast_id}`));
  }, []);

  useEffect(() => {
    if (broadcast_id) {
      dispatch(watchLiveStream(broadcast_id));
    }
  }, [broadcast_id]);

  useEffect(() => {
    const extractedBucketNames = broadcastLiveStream?.channels?.map(
      //@ts-ignore
      (item) => `${item.distributionDomainName}/${item.bucketName}`
    );
    dispatch(setbucketNames(extractedBucketNames));
  }, [broadcastLiveStream]);

  const setVodUrl = (url: string, domainName: string, index: string) => {
    dispatch(setVideoOnDemandUrl(domainName + "/" + url));
    navigate(`${ROUTE_URLS.BROADCAST_HISTORY}/${broadcast_id}/${index}`);
  };

  useEffect(() => {
    const fetchDataForBuckets = async () => {
      try {
        const apiRequests = bucketNames.map(async (bucketName) => {
          const split = bucketName.split("/");
          const response = await get(`recordings/?bucketName=${split[1]}`);
          return {
            bucketName,
            data: response.data,
            url: `https://${split[0]}`,
          };
        });
        const results = await Promise.all(apiRequests);
        setResponses(results);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data for buckets:", error);
      }
    };
    fetchDataForBuckets();
  }, [bucketNames]);

  const getTimeFromUrl = (url: string): string | null => {
    const parts = url.split("/");
    if (parts.length >= 12) {
      const year = parts[4].padStart(2, "0");
      const month = parts[5].padStart(2, "0");
      const day = parts[6].padStart(2, "0");
      const hour = parts[7].padStart(2, "0");
      const minute = parts[8].padStart(2, "0");
      return (
        dateWithMonthFormat(
          `${year}-${month}-${day}T${hour}:${minute}:00.000+00:00`
        ) +
        timeOnlyFormat(`${year}-${month}-${day}T${hour}:${minute}:00.000+00:00`)
      );
    } else {
      return "-----";
    }
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/broadcast">
      Broadcast
    </Link>,
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={`/broadcast/${broadcast_id}/0`}
    >
      Streaming
    </Link>,
    <Typography key="2" color="text.primary">
      History
    </Typography>,
  ];

  return (
    // <div>
    //   <div className="mainPageHeading">
    //     <h1
    //       style={{
    //         color: themeData === "dark" ? "white" : "",
    //         marginLeft: sidebarToggle ? "180px" : "",
    //       }}
    //       className={`${
    //         themeData === "light" ? "login-text-dark" : ""
    //       } pageHeading`}
    //     >
    //       Broadcast Recordings
    //     </h1>
    //     <div className="pageHeadingButtons"></div>
    //   </div>

    //   <Box
    //     sx={{
    //       display: "flex",
    //       flexDirection: {
    //         xs: "column-reverse",
    //         md: "row",
    //       },
    //       marginTop: { xs: "-30px", md: "0px" },
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         width: {
    //           xs: "100%",
    //           md: "20%",
    //         },
    //         paddingTop: "20px",
    //       }}
    //       className="historyMenu"
    //     >
    //       <div
    //         style={{
    //           display: "flex",
    //           alignItems: "center",
    //           width: "100%",
    //         }}
    //       >
    //         <input
    //           type="date"
    //           name="selectedDate"
    //           id="selectedDate"
    //           min="2023-01-01"
    //           onChange={(date) => {
    //             setDate(date.target.value);
    //           }}
    //           style={{
    //             width: "70%",
    //             height: "18px",
    //             padding: "5px",
    //             fontSize: "20px",
    //             border: "1px solid #6d3190",
    //             borderRadius: "5px 0px 0px 5px",
    //             color: themeData === "dark" ? "white" : "black",
    //           }}
    //         />
    //         <button
    //           style={{
    //             height: "30px",
    //             width: "28%",
    //             fontSize: "20px",
    //             border: "1px solid #6d3190",
    //             borderRadius: "0px 5px 5px 0px",
    //           }}
    //           onClick={() => {
    //             setDate("");
    //           }}
    //         >
    //           Clear
    //         </button>
    //       </div>
    //       <div>
    //         {Object.entries(groupedData).map(([channelRef, items]) => (
    //           <div
    //             style={{
    //               maxHeight: "250px",
    //               overflowY: "scroll",
    //               marginBottom: "5px",
    //             }}
    //             key={channelRef}
    //           >
    //             <h4
    //               style={{
    //                 position: "sticky",
    //                 marginTop: "0",
    //                 top: "6px",
    //                 backgroundColor: "white",
    //                 padding: "5px",
    //                 color: "#6d3190",
    //               }}
    //             >
    //               {channelRef}
    //             </h4>
    //             {items.map((item) => (
    //               <p
    //                 {...(vod_src === item?._id
    //                   ? {
    //                       style: {
    //                         background: "#6d3190",
    //                         color: "white",
    //                         paddingLeft: "15px",
    //                       },
    //                     }
    //                   : {
    //                       style: {
    //                         color: themeData === "dark" ? "white" : "black",
    //                       },
    //                     })}
    //                 onClick={() => {
    //                   setVodUrl(item?.vodURL, item?._id);
    //                 }}
    //                 key={item?._id}
    //               >
    //                 {dateWithMonthFormat(item?.createdAt)}-{" "}
    //                 {timeOnlyFormat(item?.createdAt)}
    //               </p>
    //             ))}
    //           </div>
    //         ))}
    //       </div>
    //     </Box>
    //     <Box
    //       sx={{
    //         width: { xs: "100%", md: "80%" },
    //       }}
    //     >
    //       <HistoryPlayer />
    //     </Box>
    //   </Box>
    // </div>
    <div>
      <div className="mainPageHeading">
        <h1
          style={{
            color: themeData === "dark" ? "white" : "",
            marginLeft: sidebarToggle ? "180px" : "",
          }}
          className={`${
            themeData === "light" ? "login-text-dark" : ""
          } pageHeading`}
        >
          History
        </h1>
        <Stack spacing={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </div>

      {loading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              md: "row",
            },
            marginTop: { xs: "-30px", md: "0px" },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "20%",
              },
              paddingTop: "20px",
            }}
            className="historyMenu"
          >
            {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <input
              type="date"
              name="selectedDate"
              id="selectedDate"
              min="2023-01-01"
              onChange={(date) => {
                setDate(date.target.value);
              }}
              style={{
                width: "70%",
                height: "18px",
                padding: "5px",
                fontSize: "20px",
                border: "1px solid #6d3190",
                borderRadius: "5px 0px 0px 5px",
                color: themeData === "dark" ? "white" : "black",
              }}
            />
            <button
              style={{
                height: "30px",
                width: "28%",
                fontSize: "20px",
                border: "1px solid #6d3190",
                borderRadius: "0px 5px 5px 0px",
              }}
              onClick={() => {
                setDate("");
              }}
            >
              Clear
            </button>
          </div> */}
            <div
              style={{
                maxHeight: "80vh",
                overflowY: "scroll",
                paddingRight: "2px",
              }}
            >
              {responses.map((singleResponse, index) => (
                <div
                  style={{
                    maxHeight: "250px",
                    overflowY: "scroll",
                    marginBottom: "5px",
                  }}
                  key={index}
                >
                  <h4
                    style={{
                      position: "sticky",
                      marginTop: "0",
                      top: "6px",
                      backgroundColor: "white",
                      padding: "5px",
                      color: "#6d3190",
                    }}
                  >
                    Camera: {index + 1}
                  </h4>
                  {singleResponse.data.map((item, subIndex) => (
                    <p
                      {...(vod_src === `${index}${subIndex}`
                        ? {
                            style: {
                              background: "#6d3190",
                              color: "white",
                              paddingLeft: "15px",
                            },
                          }
                        : {
                            style: {
                              color: themeData === "dark" ? "white" : "black",
                            },
                          })}
                      onClick={() => {
                        setVodUrl(
                          item,
                          singleResponse?.url,
                          `${index}${subIndex}`
                        );
                      }}
                      key={item}
                    >
                      {getTimeFromUrl(item)}
                    </p>
                  ))}
                </div>
              ))}
            </div>
            {responses?.length === 0 && (
              <p style={{ margin: "40px 5px", color: "#ea1a1a" }}>
                No recordings found
              </p>
            )}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "80%" },
            }}
          >
            <HistoryPlayer />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default BroadcastHistory;
