import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { RootState } from "../../config/store";

const HistoryPlayer = () => {
  const { vodURL } = useSelector((state: RootState) => state.broadcast);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {vodURL ? (
        <ReactPlayer
          className="react-vod-player"
          url={vodURL}
          controls={true}
          width="95%"
          height="auto"
          playing={true}
          muted
        />
      ) : (
        <p>Please select a video to see</p>
      )}
    </div>
  );
};

export default HistoryPlayer;
