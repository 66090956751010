import { string } from "yup";
import { AppDispatch } from "../../../config/store";
import { deleteChannelDataError } from "../../Channel/slices/channelSlice";

import {
  addPairDataError,
  addPairDataRequest,
  addPairDataSuccess,
  deletePairDataError,
  deletePairDataRequest,
  deletePairDataSuccess,
  deleteSinglePairDataRequest,
  deleteSinglePairDataSuccess,
  getPairDataError,
  getPairDataRequest,
  getPairDataSuccess,
  unReservedPairError,
  unReservedPairRequest,
  unReservedPairSuccess,
} from "../slices/wicketsSlice";
import PairsService from "../services/wicketsServices";

export function getAllPairs(user_id: string) {
  return (dispatch: AppDispatch) => {
    dispatch(getPairDataRequest());
    PairsService.listPairDetail(user_id)
      .then((response) => {
        const { success, data, error } = response.data;
        if (success) {
          dispatch(getPairDataSuccess(data));
        } else {
          dispatch(getPairDataError(error));
        }
      })
      .catch((error) => {
        dispatch(getPairDataError(error));
      });
  };
}

export function getUnReservedPair() {
  return (dispatch: AppDispatch) => {
    dispatch(unReservedPairRequest());
    PairsService.getUnReservedPairs()
      .then((response) => {
        const { success, data, error } = response.data;
        if (success) {
          dispatch(unReservedPairSuccess(data));
        } else {
          dispatch(unReservedPairError(error));
        }
      })
      .catch((error) => {
        dispatch(unReservedPairError(error));
      });
  };
}

export function addPairDetail(payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(addPairDataRequest());
    PairsService.addPairDetail(payload)
      .then((response) => {
        const { success, message, error } = response.data;
        if (success) {
          dispatch(addPairDataSuccess(message));
        } else {
          dispatch(addPairDataError(error));
        }
      })
      .catch((error) => {
        dispatch(addPairDataError(error));
      });
  };
}

export function updatePair(pairId: string, payload: object) {
  return (dispatch: AppDispatch) => {
    PairsService.updatePair(pairId, payload)
      .then((response) => {
        const { success, message, error } = response.data;
        if (success) {
          dispatch(addPairDataSuccess(message));
        } else {
          dispatch(addPairDataError(error));
        }
      })
      .catch((error) => {
        dispatch(addPairDataError(error));
      });
  };
}

export function deleteSinglePairDetail(device_id: string, payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(deleteSinglePairDataRequest());
    PairsService.deleteById(device_id, payload)
      .then((response) => {
        const { success, message, error } = response.data;
        if (success) {
          dispatch(deleteSinglePairDataSuccess(message));
        } else {
          dispatch(deleteChannelDataError(error));
        }
      })
      .catch((error) => {
        dispatch(deleteChannelDataError(error));
      });
  };
}

export function deleteMultiplePair(payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(deletePairDataRequest());
    PairsService.deletePair(payload)
      .then((response) => {
        const { success, message, error } = response.data;
        if (success) {
          dispatch(deletePairDataSuccess(message));
        } else {
          dispatch(deletePairDataError(error));
        }
      })
      .catch((error) => {
        dispatch(deletePairDataError(error));
      });
  };
}
