import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import ROUTE_URLS, { BASE_URL } from "./config/routes";
import RouteAuthGuard from "./helper/route-auth-guard/Route-Auth-Guard";
import RouteAuthGuardRestrictLoggedUser from "./helper/route-auth-guard-restrict-logged-user/RouteAuthGuardRestrictLoggedUser";
import NotFound from "./common/not-found";
import Login from "./components/auth/pages/Login";
import SignUp from "./components/auth/pages/SignUp";
import ForgotPassword from "./components/auth/pages/ForgotPassword";
import ResetPassword from "./components/auth/pages/ResetPassword";
import UpdatePassword from "./components/profile/pages/UpdatePassword";
import Home from "./components/Home";
import UpdateProfile from "./components/profile/pages/UpdateProfile";
import Broadcast from "./components/BroadcastManagement/pages/Broadcast";
import BroadcastStreaming from "./components/BroadcastManagement/pages/BroadcastStreaming";
import DeviceManagement from "./components/DeviceManagement/pages/Devices";
import Channel from "./components/Channel/pages/Channel";
import UserManagement from "./components/UsersManagement/pages/UserManagement";
import Pairs from "./components/Pairs/pages/Pairs";
import Dashboard from "./components/Dashboard/Dashboard";
import Analytics from "./components/Analytics/pages/Analytics";
import UserDetail from "./components/UsersManagement/pages/UserDetail";
import { ThemeProvider, createTheme } from "@mui/material";
import LocalstorageService from "./helper/localstorage-services";
import ChannelHistory from "./components/Channel/pages/ChannelHistory";
import BroadcastHistory from "./components/BroadcastManagement/pages/BroadcastHistory";
import ManageChannel from "./components/UsersManagement/pages/ManageChannel";
import RecentBroadcast from "./components/BroadcastManagement/pages/RecentBroadcast";
import Wickets from "./components/Wickets/pages/Wickets";

const App = () => {
  const [themeMode, setThemeMode] = React.useState<boolean>(false);
  const body = document.querySelector("body");

  useEffect(() => {
    const themeData = LocalstorageService.getThemeMode();
    if (themeData && themeData !== null) {
      setThemeMode(themeData === "dark" ? true : false);
    }
    if (body) {
      if (themeMode === true) {
        body.classList.add("dark");
      } else {
        body.classList.remove("dark");
        body.classList.add("light");
      }
    }
  }, [themeMode]);

  const theme = createTheme({
    shape: {
      borderRadius: 6,
    },
    palette: {
      mode: themeMode === false ? "light" : "dark",
      primary: {
        main: "#6D3190",
        dark: "#c385e7",
        light: "#C264FF",
      },
      success: {
        contrastText: "#fff",
        main: "#28C76F",
      },
      secondary: {
        main: "#DBDADE",
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            path={ROUTE_URLS.LOGIN}
            element={
              <RouteAuthGuardRestrictLoggedUser>
                <Login />
              </RouteAuthGuardRestrictLoggedUser>
            }
          />
          <Route
            path={ROUTE_URLS.SIGNUP}
            element={
              <RouteAuthGuardRestrictLoggedUser>
                <SignUp />
              </RouteAuthGuardRestrictLoggedUser>
            }
          />
          <Route
            path={ROUTE_URLS.FORGOT_PASSWORD}
            element={
              <RouteAuthGuardRestrictLoggedUser>
                <ForgotPassword />
              </RouteAuthGuardRestrictLoggedUser>
            }
          />
          <Route
            path={`${ROUTE_URLS.RESET_PASSWORD}/:reset_password_token`}
            element={
              <RouteAuthGuardRestrictLoggedUser>
                <ResetPassword />
              </RouteAuthGuardRestrictLoggedUser>
            }
          />
          <Route
            path={ROUTE_URLS.UPDATE_PASSWORD}
            element={
              <RouteAuthGuard>
                <UpdatePassword />
              </RouteAuthGuard>
            }
          />
          <Route
            path={BASE_URL}
            element={
              <RouteAuthGuard>
                <Home themeMode={themeMode} setThemeMode={setThemeMode} />
              </RouteAuthGuard>
            }
          >
            <Route
              path={ROUTE_URLS.DASHBOARD}
              element={
                <RouteAuthGuard>
                  <Dashboard />
                </RouteAuthGuard>
              }
            />
            <Route
              path={ROUTE_URLS.UPDATE_PROFILE}
              element={
                <RouteAuthGuard>
                  <UpdateProfile />
                </RouteAuthGuard>
              }
            />
            <Route
              path={ROUTE_URLS.BROADCAST_MANAGEMENT}
              element={
                <RouteAuthGuard>
                  <Broadcast />
                </RouteAuthGuard>
              }
            />
            <Route
              path={`${ROUTE_URLS.BROADCAST_STREAM}/:broadcast_id/:channel_id`}
              element={
                <RouteAuthGuard>
                  <BroadcastStreaming />
                </RouteAuthGuard>
              }
            />
            <Route
              path={`${ROUTE_URLS.BROADCAST_HISTORY}/:broadcast_id/:vod_src`}
              element={
                <RouteAuthGuard>
                  <BroadcastHistory />
                </RouteAuthGuard>
              }
            />
            <Route
              path={`${ROUTE_URLS.RECENT_BROADCAST}/:broadcast_id`}
              element={
                <RouteAuthGuard>
                  <RecentBroadcast />
                </RouteAuthGuard>
              }
            />
            <Route
              path={ROUTE_URLS.DEVICE_MANAGEMENT}
              element={
                <RouteAuthGuard>
                  <DeviceManagement />
                </RouteAuthGuard>
              }
            />
            <Route
              path={ROUTE_URLS.CHANNEL_MANAGEMENT}
              element={
                <RouteAuthGuard>
                  <Channel />
                </RouteAuthGuard>
              }
            />
            <Route
              path={ROUTE_URLS.WICKETS}
              element={
                <RouteAuthGuard>
                  <Wickets />
                </RouteAuthGuard>
              }
            />
            <Route
              path={`${ROUTE_URLS.CHANNEL_HISTORY}/:channel_id/:vod_src`}
              element={
                <RouteAuthGuard>
                  <ChannelHistory />
                </RouteAuthGuard>
              }
            />
            <Route
              path={ROUTE_URLS.USERS}
              element={
                <RouteAuthGuard>
                  <UserManagement />
                </RouteAuthGuard>
              }
            />
            <Route
              path={`${ROUTE_URLS.USER_DETAIL}/:user_id`}
              element={
                <RouteAuthGuard>
                  <UserDetail />
                </RouteAuthGuard>
              }
            />
            <Route
              path={ROUTE_URLS.ANALYTICS}
              element={
                <RouteAuthGuard>
                  <Analytics />
                </RouteAuthGuard>
              }
            />
            <Route
              path={ROUTE_URLS.CHANNEL_MANAGE}
              element={
                <RouteAuthGuard>
                  <ManageChannel />
                </RouteAuthGuard>
              }
            />
            <Route
              path={ROUTE_URLS.PAIRS_MANAGEMENT}
              element={
                <RouteAuthGuard>
                  <Pairs />
                </RouteAuthGuard>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
};

export default App;
