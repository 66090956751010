/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../config/store";
import { useDispatch, useSelector } from "react-redux";
import {
  ChannelCameraSchema,
  broadcastRecordings,
  resetEndStreamMessage,
  setbucketNames,
  setdistributionDomainName,
} from "../slices/broadcastSlice";
import ROUTE_URLS from "../../../config/routes";
import { ChannelSchema } from "../../Channel/slices/channelSlice";
import ToastService from "../../../helper/toast-services";
import {
  broadcastDetailList,
  endLiveStream,
  getBroadcastRecordings,
  watchLiveStream,
} from "../middleware/broadcast";
import DeleteModal from "../../../common/DeleteModal";
import HistoryIcon from "@mui/icons-material/History";
import LocalstorageService from "../../../helper/localstorage-services";
import ReactPlayer from "react-player";
import { put } from "../../../helper";
import notLive from "../../../assets/images/no_live_streaming.jpg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import UpdateIcon from "@mui/icons-material/Update";
import LiveTvIcon from "@mui/icons-material/LiveTv";

const BroadcastStreaming = () => {
  const theme = useTheme();
  const { broadcast_id, channel_id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const {
    broadcastLiveStreamLoading,
    broadcastLiveStream,
    endStreamLoading,
    endStreamMessage,
    endStreamErrorMessage,
  } = useSelector((state: RootState) => state.broadcast);
  const { updateChannelRange, updateChannelRangeLoading } = useSelector(
    (state: RootState) => state.channel
  );
  const { sidebarToggle } = useSelector((state: RootState) => state.users);
  const nav = useNavigate();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const [liveStream, setLiveStream] = useState<
    ChannelCameraSchema | ChannelSchema | null
  >(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isConfirmEnd, setIsConfirmEnd] = useState<boolean>(false);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const userData = LocalstorageService.getLoggedInUserDetails();
  const themeData = LocalstorageService.getThemeMode();

  // new test start
  const playerRefs = useRef<ReactPlayer[]>([]);
  const [playing, setPlaying] = useState<boolean>(true);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [liveUrl, setLiveUrl] = useState<any>([]);
  const [isLive, setIsLive] = useState<boolean[]>(
    new Array(liveUrl?.length).fill(true)
  );

  useEffect(() => {
    // Extract 'channelName' values from the initialChannels array
    const liveUrls =
      broadcastLiveStream?.channels &&
      broadcastLiveStream?.channels?.map((channel) => channel?.playbackUrl);
    setLiveUrl(liveUrls);
  }, [broadcastLiveStream?.channels]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newIsLive = playerRefs.current.map((player, index) => {
        if (player) {
          const isPlayerLive =
            player.getDuration() - player.getCurrentTime() < 30;
          return isPlayerLive;
        }
        return isLive[index];
      });

      setIsLive(newIsLive);
    }, 5000); // Check every 5 seconds

    return () => clearInterval(interval);
  }, [liveUrl, isLive]);

  const playAll = () => {
    setPlaying(true);
  };

  const pauseAll = () => {
    setPlaying(false);
  };

  const seek = (seconds: number) => {
    playerRefs.current.forEach((player) => {
      if (player) {
        const currentTime = player.getCurrentTime();
        player.seekTo(currentTime + seconds, "seconds");
      }
    });
  };

  const seekForward = () => {
    seek(5); // Seek 5 seconds forward
  };

  const seekBackward = () => {
    seek(-5); // Seek 5 seconds backward
  };

  const goToLive = () => {
    // Assuming seeking to a very large number will go to the live point
    playerRefs.current.forEach((player) => {
      if (player) {
        player.seekTo(99999, "seconds");
      }
    });
  };

  const handlePlaybackRateChange = (event: SelectChangeEvent<number>) => {
    setPlaybackRate(Number(event.target.value));
  };

  // const handlePlaybackRateChange = (rate: number) => {
  //   setPlaybackRate(rate);
  // };

  // Effect to initialize the refs array
  useEffect(() => {
    if (liveUrl) {
      playerRefs.current = playerRefs.current.slice(0, liveUrl?.length);
    }
  }, [liveUrl]);

  // new test end

  useEffect(() => {
    dispatch(broadcastDetailList(userData._id));
    dispatch(setbucketNames([]));
  }, []);

  useEffect(() => {
    dispatch(getBroadcastRecordings(`broadcastId=${broadcast_id}`));
  }, []);

  useEffect(() => {
    broadcastLiveStream?.channels[0]?.distributionDomainName &&
      dispatch(
        setdistributionDomainName(
          broadcastLiveStream?.channels[0]?.distributionDomainName +
            "/recording-started-latest.json"
        )
      );
    broadcastLiveStream?.channels[0]?.distributionDomainName &&
      saveRecordings(broadcastLiveStream?.channels[0]?.distributionDomainName!);
  }, [broadcastLiveStream?.channels[0]?.distributionDomainName]);

  useEffect(() => {
    if (
      !broadcastLiveStreamLoading &&
      !updateChannelRangeLoading &&
      initialLoading &&
      broadcastLiveStream
    ) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line
  }, [broadcastLiveStream]);
  const handleEndLiveStream = () => {
    if (broadcast_id) {
      dispatch(endLiveStream(broadcast_id, { entTime: new Date() }));
    }
  };

  useEffect(() => {
    if (broadcast_id) {
      dispatch(watchLiveStream(broadcast_id));
    }
  }, [broadcast_id, updateChannelRange]);

  useEffect(() => {
    if (liveStream !== null && !isError && updateChannelRange !== null) {
      setLiveStream(updateChannelRange);
    }
  }, [updateChannelRange]);

  useEffect(() => {
    if (!endStreamLoading && endStreamMessage) {
      setIsConfirmEnd(false);
      ToastService.success(endStreamMessage);
      dispatch(resetEndStreamMessage());
      nav(ROUTE_URLS.BROADCAST_MANAGEMENT);
    }
  }, [endStreamMessage]);

  const handleCloseConfirm = () => {
    setIsConfirmEnd(false);
  };

  // update Recordings function
  const getUpdateBrodcastData = (payload: any) => {
    return put(
      `https://bond.niftyitsolution.com/api/broadcast/updaterecording`,
      payload
    );
  };

  function updateBroadCast(update: any) {
    getUpdateBrodcastData(update)
      .then((response: any) => {
        const { success, error } = response.data;
        if (success) {
          // console.log(message);
        } else {
          console.log(error);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  const saveRecordings = async (domainName: string) => {
    const response = await fetch(
      `https://${domainName}/recording-started-latest.json`
    );
    const data = await response.json();

    if (data.isChannelLive) {
      const mstkey = data?.masterKey;
      if (mstkey) {
        const recordingsData = {
          vodURL: "https://" + domainName + "/" + mstkey,
          channelId: channel_id,
          broadcastId: broadcast_id,
          playlistDuration: data?.playlistDuration,
          recordingStartedAt: data?.recordingStartedAt,
        };
        updateBroadCast(recordingsData);
      }
    } else {
      setIsError(true);
      ToastService.error(
        "This channel is not live. Please start the live stream first."
      );
    }
  };

  const toggleSize = (index: number, domainName: string) => {
    saveRecordings(domainName);
    if (index === expandedIndex) {
      return;
    }
    setExpandedIndex(index === expandedIndex ? -1 : index);
    setIsError(false);
  };

  const handlePlayerClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  // const handleStreamError = async (domainName: string) => {
  //   const response = await fetch(
  //     `https://${domainName}/recording-started-latest.json`
  //   );
  //   const data = await response.json();
  //   if (data.isChannelLive === true) {
  //     window.location.reload();
  //   }
  // };
  useEffect(() => {
    const extractedBucketNames = broadcastLiveStream?.channels?.map(
      //@ts-ignore
      (item) => `${item.distributionDomainName}/${item.bucketName}`
    );
    dispatch(setbucketNames(extractedBucketNames));
  }, [broadcastLiveStream]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/broadcast">
      Broadcast
    </Link>,
    <Typography key="2" color="text.primary">
      Streaming
    </Typography>,
  ];

  // Keyboard control function
  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case " ": // Play/Pause toggle
        playing ? pauseAll() : playAll();
        break;
      case "ArrowRight": // Seek forward 5 second
        seekForward();
        break;
      case "ArrowLeft": // Seek backward 5 second
        seekBackward();
        break;
      case "ArrowUp": // Seek backward 5 second
        alert("Up Clicked");
        break;
      case "ArrowDown": // Seek backward 5 second
        alert("Down Clicked");
        break;
      case "l": // Go to Live
        goToLive();
        break;
      case "L": // Go to Live
        goToLive();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [playing]); // Add dependencies as required

  return (
    <>
      {broadcastLiveStreamLoading && initialLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <div className="broadcastStreaming">
          <div className="mainPageHeading">
            <h1
              style={{
                color: themeData === "dark" ? "white" : "",
                marginLeft: sidebarToggle ? "180px" : "",
              }}
              className={`${
                theme.palette.mode === "light" ? "login-text-dark" : ""
              } pageHeading`}
            >
              Broadcast Streaming
            </h1>
            <div className="pageHeadingButtons">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => {
                  // nav(`${ROUTE_URLS.RECENT_BROADCAST}/${broadcast_id}`);
                  nav(`${ROUTE_URLS.BROADCAST_HISTORY}/${broadcast_id}/00`);
                }}
                className="recordingButton"
              >
                Recent History
                <HistoryIcon />
              </Button>
            </div>
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </div>
          <div className="streamingPage">
            <div className="live-streaming">
              <div className="video-grid">
                {broadcastLiveStream &&
                  broadcastLiveStream.channels &&
                  broadcastLiveStream.channels.length > 0 &&
                  broadcastLiveStream.channels.map((url, index) => (
                    <div
                      key={index}
                      className={`video-item ${
                        expandedIndex === index ? "expanded" : ""
                      }`}
                      onClick={() =>
                        toggleSize(index, url.distributionDomainName)
                      }
                    >
                      <ReactPlayer
                        url={url.playbackUrl}
                        width="100%"
                        height="100%"
                        playing={playing}
                        playbackRate={playbackRate}
                        ref={(ref) => {
                          if (ref) {
                            playerRefs.current[index] = ref;
                          }
                        }}
                        onClick={(e: { preventDefault: () => void }) =>
                          handlePlayerClick(e)
                        }
                        light={
                          expandedIndex === index && isError ? (
                            <img src={notLive} width={"95%"} alt="not-found" />
                          ) : (
                            false
                          )
                        }
                        onPlay={() => {
                          setIsError(false);
                        }}
                        onError={(error) => {
                          // console.log(error);
                        }}
                        controls={expandedIndex === index ? true : false}
                        muted
                      />
                      {/* previous video player handling live player  */}
                      {/* <App data={url.distributionDomainName} /> */}
                    </div>
                  ))}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "15px 0px",
                }}
              >
                <IconButton
                  color={themeData === "light" ? "primary" : "secondary"}
                  aria-label="Seek Backward"
                  onClick={seekBackward}
                >
                  <HistoryIcon />
                </IconButton>
                {playing ? (
                  <IconButton
                    color={themeData === "light" ? "primary" : "secondary"}
                    aria-label="Pause"
                    onClick={pauseAll}
                  >
                    <PauseIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color={themeData === "light" ? "primary" : "secondary"}
                    aria-label="Play"
                    onClick={playAll}
                  >
                    <PlayArrowIcon />
                  </IconButton>
                )}
                <IconButton
                  color={themeData === "light" ? "primary" : "secondary"}
                  aria-label="Seek Forward"
                  onClick={seekForward}
                >
                  <UpdateIcon />
                </IconButton>
                <IconButton
                  color={themeData === "light" ? "primary" : "secondary"}
                  aria-label="Goto Live"
                  onClick={goToLive}
                >
                  <LiveTvIcon />
                </IconButton>
                <FormControl
                  variant="outlined"
                  style={{ minWidth: "80px" }}
                  className="playebackSpeedButton"
                >
                  <InputLabel id="playback-speed-label">Speed</InputLabel>
                  <Select
                    labelId="playback-speed-label"
                    id="playback-speed-select"
                    value={playbackRate}
                    onChange={handlePlaybackRateChange}
                    label="Playback Speed"
                  >
                    <MenuItem value={0.25}>0.25x</MenuItem>
                    <MenuItem value={0.5}>0.5x</MenuItem>
                    <MenuItem value={1}>1x</MenuItem>
                    <MenuItem value={1.5}>1.5x</MenuItem>
                    <MenuItem value={2}>2x</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      )}

      <DeleteModal
        handleClose={handleCloseConfirm}
        handleOnDelete={handleEndLiveStream}
        isOpen={isConfirmEnd}
        loading={endStreamLoading}
        deleteErrorMessage={endStreamErrorMessage}
        title="You will be able to watch the recorded stream after the stream ends."
        buttonName="End"
      />
    </>
  );
};

export default BroadcastStreaming;
