import React from "react";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Alert,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ROUTE_URLS from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import ToastService from "../../../helper/toast-services";
import { resetLogoutUser } from "../../profile/slices/profileSlice";
import { authValidation } from "../validation/authValidationSchema";
import { loginUser } from "../middleware/auth";
import { AppDispatch, RootState } from "../../../config/store";
import footer from "../../../assets/images/loginFooter.svg";
import { resetMessage } from "../slices/authSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoginInterface } from "../../../common/interfaces/interface";

export default function Login() {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();

  const { loginLoading, loginError, loginMessage } = useSelector(
    (state: RootState) => state.auth
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const initialValues: LoginInterface = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (loginMessage && !loginError) {
      ToastService.success(loginMessage);
      navigate(ROUTE_URLS.DASHBOARD);
      formik.resetForm();
      dispatch(resetMessage());
    }
  }, [loginMessage]);

  const handleSubmit = (values: LoginInterface) => {
    // API dispatch calling here... //
    dispatch(loginUser(values));
    dispatch(resetLogoutUser());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: authValidation,
  });

  return (
    <div className="center-auth-component">
      <CssBaseline />
      <div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 350,
          }}
        >
          <h1
            className={`${
              theme.palette.mode === "light" ? "login-text-dark" : ""
            } login-text`}
            style={{ fontSize: "48px" }}
          >
            Login
          </h1>
          <h5
            className={`${
              theme.palette.mode === "light" ? "login-text-dark" : ""
            } login-content`}
          >
            Sign in and start managing your devices!
          </h5>
          {loginError && <Alert severity="error">{loginError}</Alert>}
          <form onSubmit={formik.handleSubmit}>
            <div>
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-error"
                name="email"
                label="Username"
                placeholder="Enter email address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                autoComplete="off"
                size="small"
                style={{ marginTop: "20px" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="standard-password-input"
                name="password"
                label="Password"
                placeholder="Enter password"
                type={`${showPassword ? "text" : "password"}`}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                autoComplete="off"
                size="small"
                style={{ marginTop: "20px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Link
                className={`${
                  theme.palette.mode === "dark" ? "text-light" : ""
                } forgot-link`}
                to={ROUTE_URLS.FORGOT_PASSWORD}
              >
                Forgot Password?
              </Link>
              <FormControlLabel
                style={{ marginTop: "50px" }}
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                style={{ marginBlock: "20px" }}
                className="loginButton2"
              >
                {loginLoading ? <CircularProgress color="inherit" /> : "Login"}
              </Button>

              <p
                style={{ zIndex: "5000" }}
                className={`${
                  theme.palette.mode === "dark"
                    ? "register-link-light"
                    : "register-link-dark"
                } register-link`}
              >
                Don’t have an account?{" "}
                <Link className="login-signup-link" to={ROUTE_URLS.SIGNUP}>
                  Register here!
                </Link>
              </p>
            </div>
          </form>
        </Box>

        {/*  Don't remove copyright commented code*/}
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </div>
      <img className="footer-wave" src={footer} alt="footer" />
    </div>
  );
}
