/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import ToastService from "../../../helper/toast-services";
import deleteBox from "../../../assets/images/trash.svg";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../config/store";
import AddIcon from "@mui/icons-material/Add";
import {
  resetDeleteMessage,
  resetDeviceMessage,
  resetEditDeviceMessage,
} from "../slices/deviceSlice";
import {
  addDeviceData,
  getDeleteDeviceData,
  getDeviceData,
  getOneDeviceData,
  getUpdateDeviceData,
} from "../middleware/device";
import CloseIcon from "@mui/icons-material/Close";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { deviceValidation } from "../validation/deviceValidationSchema";
import { dateWithMonthFormat } from "../../../helper/dateFormate";
import DeleteModal from "../../../common/DeleteModal";
import editPencil from "../../../assets/images/editPencil.svg";
import LocalstorageService from "../../../helper/localstorage-services";

interface Data {
  date_registered: string;
  serial: string;
  deviceName: string;
  status: string;
  action: HTMLElement;
}

interface WifiSettingSchema {
  ssid: string;
  password: string;
}

export type WifiSettingInterface = WifiSettingSchema[];

interface DeviceDetailsInterface {
  ssh_address: string;
  ngrockId: string;
  deviceName: string;
  serial: string;
  enable_ssh: boolean;
  status: boolean;
  deviceType: string;
  default_bitrate: number;
  doResetWifi: boolean;
  is_reserve: boolean;
  output_watchdog_timeout: number;
  video_duration: number | string;
  video_output: string;
  skip_audio_value: number | string;
  wifi_settings: WifiSettingInterface;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "date_registered",
    numeric: false,
    disablePadding: false,
    label: "Date Registered",
  },
  {
    id: "deviceName",
    numeric: false,
    disablePadding: false,
    label: "Device Name",
  },
  {
    id: "serial",
    numeric: false,
    disablePadding: false,
    label: "Serial Code",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

const defaultWifiSettingObject = {
  ssid: "",
  password: "",
};

export default function DeviceManagement() {
  const [selected, setSelected] = useState<string[]>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [createDevice, setCreateDevice] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const {
    addDeviceDataMsg,
    addDeviceDataError,
    addDeviceDataLoading,

    deviceDataLoading,
    deviceData,
    deviceDataError,

    getOneDeviceLoading,
    getOneDevice,
    getOneDeviceError,

    deleteDeviceMessage,
    deleteDeviceLoading,
    deleteDeviceError,

    updateDeviceMessage,
    updateDeviceError,
    updateDeviceLoading,
  } = useSelector((state: RootState) => state.device);
  const { sidebarToggle } = useSelector((state: RootState) => state.users);

  const userData = LocalstorageService.getLoggedInUserDetails();
  const adminRole = LocalstorageService.getLoggedInAdminDetails().role;
  const themeData = LocalstorageService.getThemeMode();

  const dispatch: AppDispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (
      !deviceDataLoading &&
      !addDeviceDataLoading &&
      initialLoading &&
      deviceData
    ) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line
  }, [deviceData]);

  useEffect(() => {
    if (!getOneDeviceLoading && getOneDevice) {
      formik.setFieldValue("serial", getOneDevice?.serial);
      formik.setFieldValue("deviceName", getOneDevice?.deviceName);
      formik.setFieldValue("deviceType", getOneDevice?.deviceType);
      formik.setFieldValue("status", getOneDevice?.status);
      formik.setFieldValue("doResetWifi", getOneDevice?.doResetWifi);
      formik.setFieldValue("is_reserve", getOneDevice?.is_reserve);
      formik.setFieldValue("enable_ssh", getOneDevice?.enable_ssh);
      formik.setFieldValue("ssh_address", getOneDevice?.ssh_address); //todo: delete it ->showing ssh address for python programmer
      formik.setFieldValue("ngrockId", getOneDevice?.ngrockId); //todo: delete it ->showing ssh address for python programmer
      formik.setFieldValue(
        "default_bitrate",
        getOneDevice?.settings?.default_bitrate
      );
      formik.setFieldValue(
        "output_watchdog_timeout",
        getOneDevice?.settings?.output_watchdog_timeout
      );
      formik.setFieldValue(
        "skip_audio_value",
        getOneDevice?.settings?.skip_audio_value
      );
      formik.setFieldValue(
        "video_duration",
        getOneDevice?.settings?.video_duration
      );
      formik.setFieldValue(
        "video_output",
        getOneDevice?.settings?.video_output
      );
      formik.setFieldValue("wifi_settings", getOneDevice?.wifi_settings);
    }
    // eslint-disable-next-line
  }, [getOneDevice]);

  useEffect(() => {
    if (addDeviceDataMsg) {
      ToastService.success(addDeviceDataMsg);
      handleCloseModal();
      closeCreateDevice();
    } else if (!updateDeviceLoading && updateDeviceMessage) {
      ToastService.success(updateDeviceMessage);
      handleCloseModal();
    }
    if (deviceDataError) {
      ToastService.error(deviceDataError);
      dispatch(resetDeviceMessage());
    }
    if (!deleteDeviceLoading && deleteDeviceMessage) {
      ToastService.success(deleteDeviceMessage);
      handleCloseDeleteModal();
    }
    // eslint-disable-next-line
  }, [
    addDeviceDataMsg,
    deviceDataError,
    deleteDeviceMessage,
    updateDeviceMessage,
  ]);

  useEffect(() => {
    dispatch(getDeviceData(userData._id));
    // eslint-disable-next-line
  }, [addDeviceDataMsg, deleteDeviceMessage, updateDeviceMessage]);

  const handleChange = (id: string, method: string) => {
    if (selected.includes(id)) {
      const data = [...selected];
      const dataIndex = data.findIndex((o) => {
        return o === id;
      });
      data.splice(dataIndex, 1);
      setSelected(data);
    } else {
      setSelected([...selected, id]);
    }
    if (method === "single") {
      setOpenDeleteDialog(true);
    }
  };

  const handleCreateDevice = (event: any) => {
    event.preventDefault();
    const form = event.target;
    const deviceName = form.deviceName?.value || "";
    const serial = form.serial?.value || "";

    const payload = {
      deviceName,
      serial,
      deviceType: "Orange Pi", //todo; need to handle this default device type value
    };
    dispatch(addDeviceData(payload));
  };

  const handleSubmit = (values: DeviceDetailsInterface) => {
    const payload = {
      deviceName: values?.deviceName,
      deviceType: values?.deviceType,
      is_reserve: values?.is_reserve,
      doResetWifi: values?.doResetWifi,
      serial: values?.serial,
      status: values?.status,
      enable_ssh: values?.enable_ssh,
      settings: {
        default_bitrate: values?.default_bitrate,
        output_watchdog_timeout: values?.output_watchdog_timeout,
        video_duration: values?.video_duration,
        video_output: values?.video_output,
        skip_audio_value: values?.skip_audio_value,
      },
      wifi_settings: values?.wifi_settings,
      ngrockId: values?.ngrockId,
    };
    if (getOneDevice) {
      dispatch(getUpdateDeviceData(getOneDevice?._id, payload));
      // console.log("update");
    } else {
      dispatch(addDeviceData(payload));
      // console.log("new");
    }
  };

  const closeCreateDevice = () => {
    setCreateDevice(false);
    dispatch(resetDeviceMessage());
    formik.resetForm();
  };
  const handleCloseModal = () => {
    setOpenAddDialog(false);
    formik.resetForm();
    dispatch(resetDeviceMessage());
    dispatch(resetEditDeviceMessage());
  };

  const handleCloseDeleteModal = () => {
    setSelected([]);
    setOpenDeleteDialog(false);
    dispatch(resetDeleteMessage());
  };

  const initialDeviceValues: DeviceDetailsInterface = {
    deviceName: "",
    serial: "",
    is_reserve: true,
    enable_ssh: true,
    status: false,
    doResetWifi: false,
    deviceType: "",
    default_bitrate: 2000,
    output_watchdog_timeout: 30000,
    video_duration: "",
    video_output: "",
    skip_audio_value: "",
    wifi_settings: [
      {
        ssid: "",
        password: "",
      },
    ],
    ssh_address: "",
    ngrockId: "",
  };

  const handleAddNewService = (arrayHelpers: any) => {
    arrayHelpers.push(defaultWifiSettingObject);
  };

  const formik = useFormik({
    initialValues: initialDeviceValues,
    onSubmit: handleSubmit,
    validationSchema: deviceValidation,
  });

  const handleDelete = () => {
    dispatch(getDeleteDeviceData({ _id: selected }));
  };

  const handleEdit = (id: string) => {
    dispatch(getOneDeviceData(id));
    setOpenAddDialog(true);
  };

  return (
    <>
      <div className="mainPageHeading">
        <h1
          style={{
            color: themeData === "dark" ? "white" : "",
            marginLeft: sidebarToggle ? "180px" : "",
          }}
          className={`${
            theme.palette.mode === "light" ? "login-text-dark" : ""
          } pageHeading`}
        >
          Devices
        </h1>
        <div className="pageHeadingButtons">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            style={{ width: "260px" }}
            onClick={() => setCreateDevice(true)}
            className="loginButton"
          >
            <AddIcon />
            Add Device
          </Button>
          <Button
            color="primary"
            sx={{
              borderColor: `${
                theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }`,
              color: `${
                theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }`,
              "&:hover": {
                borderColor: `${
                  theme.palette.mode === "dark"
                    ? "primary.dark"
                    : "primary.main"
                }`,
                color: `${
                  theme.palette.mode === "dark"
                    ? "primary.dark"
                    : "primary.main"
                }`,
              },
            }}
            variant="outlined"
            type="submit"
            onClick={() => {
              setOpenDeleteDialog(true);
            }}
            className="deleteButton"
          >
            Delete
          </Button>
        </div>
      </div>
      {deviceDataLoading && initialLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                      >
                        <span
                          className={`${
                            theme.palette.mode === "light"
                              ? "login-text-dark"
                              : ""
                          } tableHead`}
                        >
                          {headCell.label}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deviceData &&
                    deviceData.length > 0 &&
                    deviceData.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              sx={{
                                color: "#A8AAAE",
                              }}
                              color="primary"
                              value={row._id}
                              onChange={() => handleChange(row._id, "multiple")}
                              checked={
                                selected.includes(row._id) ? true : false
                              }
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {row.createdAt
                                ? dateWithMonthFormat(row.createdAt)
                                : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {" "}
                              {row.deviceName ? row.deviceName : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {row.serial ? row.serial : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            {row.is_reserve ? (
                              <p style={{ color: "#EA5455" }}>Researved</p>
                            ) : (
                              <p style={{ color: "#28C76F" }}>Not Reserved</p>
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={5}>
                                <img
                                  onClick={() => handleEdit(row._id)}
                                  src={editPencil}
                                  alt="edit"
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <img
                                  onClick={() =>
                                    handleChange(row._id, "single")
                                  }
                                  src={deleteBox}
                                  alt="delete"
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {deviceData && deviceData.length === 0 && (
              <span
                className={`${
                  theme.palette.mode === "light" ? "login-text-dark" : ""
                } no-data-found tableRows`}
              >
                No Devices Found
              </span>
            )}
          </Paper>
        </Box>
      )}

      {/* create form  */}
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={createDevice}
        onClose={closeCreateDevice}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="modalHead">
            <span>Device</span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={closeCreateDevice}
            />
          </div>
        </DialogTitle>
        <form onSubmit={handleCreateDevice}>
          <DialogContent>
            {addDeviceDataError && (
              <Alert severity="error">{addDeviceDataError}</Alert>
            )}
            <TextField
              fullWidth
              required
              variant="outlined"
              id="deviceName"
              name="deviceName"
              label="Device Name"
              placeholder="Enter Device name"
              type={"text"}
              value={formik.values.deviceName}
              onChange={formik.handleChange}
              error={
                formik.touched.deviceName && Boolean(formik.errors.deviceName)
              }
              helperText={formik.touched.deviceName && formik.errors.deviceName}
              onBlur={formik.handleBlur}
              style={{ marginTop: "20px" }}
            />
            <TextField
              fullWidth
              required
              variant="outlined"
              id="serial"
              name="serial"
              label="Device Serial"
              placeholder="Enter Device Serial"
              type={"text"}
              value={formik.values.serial}
              onChange={formik.handleChange}
              error={formik.touched.serial && Boolean(formik.errors.serial)}
              helperText={formik.touched.serial && formik.errors.serial}
              onBlur={formik.handleBlur}
              style={{ marginTop: "20px" }}
            />
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "center",
              position: "sticky",
              bottom: "0",
              backgroundColor: `${
                theme.palette.mode === "dark" ? "#383838" : "white"
              }`,
              zIndex: "10",
              paddingBottom: "20px",
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              sx={{
                borderColor: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                color: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                "&:hover": {
                  borderColor: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                  color: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                },
              }}
              type="button"
              size="large"
              style={{ width: "270px" }}
              className="loginButton"
              onClick={closeCreateDevice}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              style={{ width: "270px" }}
              type="submit"
              size="large"
              disabled={addDeviceDataLoading || updateDeviceLoading}
              className="loginButton"
            >
              {addDeviceDataLoading || updateDeviceLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                "Confirm"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* edit form */}

      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={openAddDialog}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="modalHead">
            <span>Device</span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleCloseModal}
            />
          </div>
        </DialogTitle>
        {getOneDeviceLoading ? (
          <div className="spinnerDiv">
            <CircularProgress />
          </div>
        ) : (
          // <form onSubmit={handleDeviceSubmit}>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              {(addDeviceDataError ||
                getOneDeviceError ||
                updateDeviceError) && (
                <Alert severity="error">
                  {addDeviceDataError || getOneDeviceError || updateDeviceError}
                </Alert>
              )}
              <TextField
                fullWidth
                variant="outlined"
                id="deviceName"
                name="deviceName"
                label="Device Name"
                placeholder="Enter Device name"
                type={"text"}
                value={formik.values.deviceName}
                onChange={formik.handleChange}
                error={
                  formik.touched.deviceName && Boolean(formik.errors.deviceName)
                }
                helperText={
                  formik.touched.deviceName && formik.errors.deviceName
                }
                onBlur={formik.handleBlur}
                style={{ marginTop: "20px" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="serial"
                name="serial"
                label="Serial Code"
                placeholder="Enter serial code"
                type={"text"}
                value={formik.values.serial}
                onChange={formik.handleChange}
                error={formik.touched.serial && Boolean(formik.errors.serial)}
                helperText={formik.touched.serial && formik.errors.serial}
                onBlur={formik.handleBlur}
                InputProps={{
                  readOnly: true,
                }}
                style={{ marginTop: "20px" }}
              />
              {adminRole === "admin" && (
                <>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="ngrockId"
                    name="ngrockId"
                    label="ngrockId"
                    placeholder="Enter ngrockId"
                    type={"text"}
                    value={formik.values.ngrockId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.ngrockId && Boolean(formik.errors.ngrockId)
                    }
                    helperText={
                      formik.touched.ngrockId && formik.errors.ngrockId
                    }
                    onBlur={formik.handleBlur}
                    style={{ marginTop: "20px" }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="ssh_address"
                    name="ssh_address"
                    label="ssh_address"
                    placeholder="ssh_address"
                    type={"text"}
                    value={formik.values.ssh_address}
                    onChange={formik.handleChange}
                    style={{ marginTop: "20px" }}
                  />
                  {/* todo: delete this, showing for python programmer  */}
                  <FormControl
                    style={{ marginTop: "20px" }}
                    fullWidth
                    error={
                      formik.touched.deviceType &&
                      Boolean(formik.errors.deviceType)
                    }
                  >
                    <InputLabel id="demo-simple-select-error-label">
                      Device Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-error-label"
                      id="deviceType"
                      value={formik.values.deviceType}
                      label="Device Type"
                      name="deviceType"
                      // onChange={formik.handleChange}
                      // input={<OutlinedInput label="Device" />}
                      onChange={(event) => {
                        formik.handleChange(event);
                        const selectedDeviceType = event.target.value;
                        let updatedData: any[] = [];
                        if (selectedDeviceType === "Orange Pi") {
                          updatedData = ["5", "0"];
                        } else if (
                          selectedDeviceType === "Orange Pi Development Board"
                        ) {
                          updatedData = ["5", "-1"];
                        } else if (selectedDeviceType === "Rock Pi") {
                          updatedData = ["1", "0"];
                        }

                        // Seting the value of video_duration, skip_audio_value field
                        formik.setFieldValue("video_duration", updatedData[0]);
                        formik.setFieldValue(
                          "skip_audio_value",
                          updatedData[1]
                        );
                      }}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="Orange Pi">Orange Pi</MenuItem>
                      <MenuItem value="Orange Pi Development Board">
                        Orange Pi Development Board
                      </MenuItem>
                      <MenuItem value="Rock Pi">Rock Pi</MenuItem>
                    </Select>
                    <FormHelperText>
                      {/* {formik.touched.pairs && formik.errors.pairs} */}
                    </FormHelperText>
                  </FormControl>
                </>
              )}
              <FormGroup
                aria-label="position"
                row
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  value={formik.values.is_reserve}
                  name="is_reserve"
                  id="is_reserve"
                  onChange={formik.handleChange}
                  checked={formik.values.is_reserve}
                  control={<Switch color="primary" />}
                  label="Reserved"
                  labelPlacement="start"
                />
                {adminRole === "admin" ? (
                  <FormControlLabel
                    value={formik.values.enable_ssh}
                    name="enable_ssh"
                    id="enable_ssh"
                    checked={formik.values.enable_ssh}
                    onChange={formik.handleChange}
                    control={<Switch color="primary" />}
                    label="Enable SSH"
                    labelPlacement="start"
                  />
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="medium"
                    style={{ width: "140px" }}
                    className="reqSshButton"
                  >
                    Get Support
                  </Button>
                )}
              </FormGroup>
              {adminRole === "admin" && (
                <>
                  <div className="sub-heading">Settings</div>
                  <Grid style={{ marginTop: "20px" }} container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel id="demo-simple-select-error-label">
                        Bitrate
                      </InputLabel>
                      <Slider
                        aria-label="default_bitrate"
                        name="default_bitrate"
                        defaultValue={2000}
                        value={formik.values.default_bitrate}
                        onChange={formik.handleChange}
                        valueLabelDisplay="on"
                        step={100}
                        min={1000}
                        max={4000}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel id="demo-simple-select-error-label">
                        Output Watchdog Timeout
                      </InputLabel>
                      <Slider
                        aria-label="output_watchdog_timeout"
                        defaultValue={20000}
                        name="output_watchdog_timeout"
                        value={formik.values.output_watchdog_timeout}
                        onChange={formik.handleChange}
                        valueLabelDisplay="on"
                        step={500}
                        min={10000}
                        max={30000}
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="video_duration"
                    name="video_duration"
                    label="Video Duration"
                    placeholder="Enter Video duration"
                    type={"number"}
                    value={formik.values.video_duration}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.video_output &&
                      Boolean(formik.errors.video_duration)
                    }
                    helperText={
                      formik.touched.video_output &&
                      formik.errors.video_duration
                    }
                    onBlur={formik.handleBlur}
                    style={{ marginTop: "20px" }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="skip_audio_value"
                    name="skip_audio_value"
                    label="Skip Audio Value"
                    placeholder="Enter Skip Audio Value"
                    type={"number"}
                    value={formik.values.skip_audio_value}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.skip_audio_value &&
                      Boolean(formik.errors.skip_audio_value)
                    }
                    helperText={
                      formik.touched.skip_audio_value &&
                      formik.errors.skip_audio_value
                    }
                    onBlur={formik.handleBlur}
                    style={{ marginTop: "20px" }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="video_output"
                    name="video_output"
                    label="Video Output"
                    placeholder="Enter Video output"
                    type={"text"}
                    value={formik.values.video_output}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.video_output &&
                      Boolean(formik.errors.video_output)
                    }
                    helperText={
                      formik.touched.video_output && formik.errors.video_output
                    }
                    onBlur={formik.handleBlur}
                    style={{ marginTop: "20px" }}
                  />
                </>
              )}
              <FormikProvider value={formik}>
                <Form>
                  <FieldArray
                    name="wifi_settings"
                    render={(arrayHelpers) => (
                      <>
                        <div className="fieldAddBtn">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 5,
                            }}
                          >
                            <div>Wifi Details</div>
                            <Button
                              color="primary"
                              variant="outlined"
                              sx={{
                                borderColor: `${
                                  theme.palette.mode === "dark"
                                    ? "primary.light"
                                    : "primary.main"
                                }`,
                                color: `${
                                  theme.palette.mode === "dark"
                                    ? "primary.light"
                                    : "primary.main"
                                }`,
                                "&:hover": {
                                  borderColor: `${
                                    theme.palette.mode === "dark"
                                      ? "primary.dark"
                                      : "primary.main"
                                  }`,
                                  color: `${
                                    theme.palette.mode === "dark"
                                      ? "primary.dark"
                                      : "primary.main"
                                  }`,
                                },
                              }}
                              type="button"
                              size="small"
                              className="loginButton"
                              onClick={() => handleAddNewService(arrayHelpers)}
                            >
                              +
                            </Button>
                          </div>
                          <FormControlLabel
                            value={formik.values.doResetWifi}
                            name="doResetWifi"
                            id="doResetWifi"
                            onChange={formik.handleChange}
                            checked={formik.values.doResetWifi}
                            control={<Switch color="primary" />}
                            label="Reset Wifi"
                            labelPlacement="start"
                          />
                        </div>
                        {formik.values.wifi_settings &&
                          formik.values.wifi_settings.length > 0 &&
                          formik.values.wifi_settings.map(
                            (o: WifiSettingSchema, index: number) => (
                              <React.Fragment key={index}>
                                <Card sx={{ marginBottom: "20px" }}>
                                  <CardContent>
                                    <CloseIcon
                                      style={{
                                        cursor: "pointer",
                                        float: "right",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      id={`wifi_settings[${index}].ssid`}
                                      name={`wifi_settings[${index}].ssid`}
                                      label="SSID"
                                      placeholder="Enter SSID"
                                      type={"text"}
                                      value={o.ssid}
                                      onChange={formik.handleChange}
                                      // error={
                                      //   formik.touched?.wifi_settings &&
                                      //   formik.touched?.wifi_settings[index]
                                      //     ?.ssid &&
                                      //   formik.errors?.wifi_settings &&
                                      //   formik.errors?.wifi_settings[index]
                                      //     ?.ssid
                                      // }
                                      // helperText={
                                      //   formik.touched.wifi_settings &&
                                      //   formik.touched.wifi_settings[index]
                                      //     .ssid &&
                                      //   formik.errors?.wifi_settings &&
                                      //   formik.errors?.wifi_settings[index]
                                      //     ?.ssid
                                      // }
                                      onBlur={formik.handleBlur}
                                      style={{ marginTop: "20px" }}
                                    />
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      id={`wifi_settings[${index}].password`}
                                      name={`wifi_settings[${index}].password`}
                                      label="Password"
                                      placeholder="Enter password"
                                      type={"text"}
                                      value={o.password}
                                      onChange={formik.handleChange}
                                      // error={
                                      //   formik.touched?.wifi_settings &&
                                      //   formik.touched?.wifi_settings[index]
                                      //     ?.password &&
                                      //   formik.errors?.wifi_settings &&
                                      //   formik.errors?.wifi_settings[index]
                                      //     ?.password
                                      // }
                                      // helperText={
                                      //   formik.touched.wifi_settings &&
                                      //   formik.touched.wifi_settings[index]
                                      //     .password &&
                                      //   formik.errors?.wifi_settings &&
                                      //   formik.errors?.wifi_settings[index]
                                      //     ?.password
                                      // }
                                      onBlur={formik.handleBlur}
                                      style={{ marginTop: "20px" }}
                                    />
                                  </CardContent>
                                </Card>
                              </React.Fragment>
                            )
                          )}
                      </>
                    )}
                  />
                </Form>
              </FormikProvider>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: "center",
                position: "sticky",
                bottom: "0",
                backgroundColor: `${
                  theme.palette.mode === "dark" ? "#383838" : "white"
                }`,
                zIndex: "10",
                paddingBottom: "20px",
              }}
            >
              <Button
                color="primary"
                variant="outlined"
                sx={{
                  borderColor: `${
                    theme.palette.mode === "dark"
                      ? "primary.light"
                      : "primary.main"
                  }`,
                  color: `${
                    theme.palette.mode === "dark"
                      ? "primary.light"
                      : "primary.main"
                  }`,
                  "&:hover": {
                    borderColor: `${
                      theme.palette.mode === "dark"
                        ? "primary.dark"
                        : "primary.main"
                    }`,
                    color: `${
                      theme.palette.mode === "dark"
                        ? "primary.dark"
                        : "primary.main"
                    }`,
                  },
                }}
                type="button"
                size="large"
                style={{ width: "270px" }}
                className="loginButton"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{ width: "270px" }}
                type="submit"
                size="large"
                disabled={addDeviceDataLoading || updateDeviceLoading}
                // onClick={() => setOpenAddDialog(false)}
                className="loginButton"
              >
                {addDeviceDataLoading || updateDeviceLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Confirm"
                )}
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>

      <DeleteModal
        handleClose={handleCloseDeleteModal}
        handleOnDelete={handleDelete}
        isOpen={openDeleteDialog}
        loading={deleteDeviceLoading}
        deleteErrorMessage={deleteDeviceError}
        title="Once it is deleted you will not be able to recover the data again!"
        buttonName="Delete"
      />
    </>
  );
}
