/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppDispatch, RootState } from "../../../config/store";
import { useDispatch, useSelector } from "react-redux";
import LocalstorageService from "../../../helper/localstorage-services";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import streamHelp from "../../../assets/gif/help.gif";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { addPairDetail, getAllPairs } from "../middleware/wickets";
import {
  getAllChannel,
  getUnReservedChannel,
  updateChannelDetail,
} from "../../Channel/middleware/channel";
import {
  getDeviceData,
  getOneDeviceData,
  getUnReservedDevice,
  getUpdateDeviceData,
} from "../../DeviceManagement/middleware/device";
import ToastService from "../../../helper/toast-services";

interface WifiSettings {
  name: string;
  password: string;
}

// interface ChannelSchema {
//   _id: string;
//   deviceName: string;
//   wifiSettings: WifiSettings;
//   camera1: string;
//   camera2: string;
//   streamKey: string;
// }

interface ChannelSchema {
  channelName: string;
  channelReference: string;
  deviceName: string;
  camera: string;
  _id: string;
  userId: string;
  createdAt: string;
  status: string;
}

interface PairValueInterface {
  device: string;
  camera1: string;
  camera2: string;
  brightness1: number;
  brightness2: number;
  withBalance1: number;
  withBalance2: number;
}
interface WifiSettingSchema {
  ssid: string;
  password: string;
}

export type WifiSettingInterface = WifiSettingSchema[];

const wickets = [
  {
    _id: "1",
    deviceName: "Device 1",
    wifiSettings: [
      { ssid: "Nifty_WiFi", password: "password123" },
      { ssid: "Nifty_5G", password: "password789" },
    ],
    camera1: "5f2b18e711d428001b2c1ef6",
    camera2: "5f2b18e711d328001b2c1ef6",
    streamKey: "a1b2-c3d4-e5f6-g7h8",
  },
  {
    _id: "2",
    deviceName: "Device 2",
    wifiSettings: [{ ssid: "WiFi_Device2", password: "password456" }],
    camera1: "5f3c29f722e429002c3d2fg7",
    camera2: "5f3c29f722e429002c3d2fg7",
    streamKey: "",
  },
  {
    _id: "3",
    deviceName: "Device 3",
    wifiSettings: [{ ssid: "WiFi_Device3", password: "password789" }],
    camera1: "5g4d31h833f432003d4e3gh8",
    camera2: "5g4d31h833f430003d4e3gh8",
    streamKey: "q7r8-s9t0-u1v2-w3x4",
  },
];

const defaultWifiSettingObject = {
  ssid: "",
  password: "",
};

const Wickets = () => {
  const userData = LocalstorageService.getLoggedInUserDetails();

  const theme = useTheme();
  const themeData = LocalstorageService.getThemeMode();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch: AppDispatch = useDispatch();

  const { sidebarToggle } = useSelector((state: RootState) => state.users);
  const {
    pairDetail,
    pairDetailLoading,
    addPairMessage,
    addPairLoading,
    addPairError,
  } = useSelector((state: RootState) => state.wickets);

  const { channelDetailLoading, channelDetail, unReservedChannel } =
    useSelector((state: RootState) => state.channel);

  const { getOneDeviceLoading, getOneDevice, updateDeviceMessage, deviceData } =
    useSelector((state: RootState) => state.device);

  const [helpOpen, setHelpOpen] = useState(false);
  const [pairDataForTable, setPairDataForTable] = useState<Array<any>>([]);
  const [addWicketsOpen, setAddWicketsOpen] = useState(false);
  const [wifiSettings, setWifiSettings] = useState(false);
  const [cameraSettings, setCameraSettings] = useState(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [emptyDeviceMessage, setEmptyDeviceMessage] = useState<boolean>(false);
  const [selectedPair, setSelectedPair] = useState<Array<string>>([]);
  const [remainingChannel, setRemainingChannel] = useState<
    Array<ChannelSchema>
  >([]);

  const [checklist, setChecklist] = useState<any>([]);

  useEffect(() => {
    setPairDataForTable(pairDetail);
    if (!pairDetailLoading && initialLoading && pairDetail) {
      setInitialLoading(false);
    }
  }, [pairDetail]);

  useEffect(() => {
    dispatch(getAllPairs(userData._id));
    dispatch(getAllChannel(userData._id));
    dispatch(getUnReservedDevice());
    dispatch(getUnReservedChannel());
  }, [addPairMessage]);

  useEffect(() => {
    dispatch(getDeviceData(userData._id));
  }, [updateDeviceMessage]);

  useEffect(() => {
    getOneDevice &&
      formik.setFieldValue("wifi_settings", getOneDevice?.wifi_settings);
  }, [getOneDevice]);

  const handleChange = (id: string) => {
    if (selectedPair.includes(id)) {
      const data = [...selectedPair];
      const dataIndex = data.findIndex((o) => {
        return o === id;
      });
      data.splice(dataIndex, 1);
      setSelectedPair(data);
    } else {
      setSelectedPair([...selectedPair, id]);
    }
  };

  const handleHelpOpen = () => setHelpOpen(true);
  const handleHelpClose = () => setHelpOpen(false);
  const handleAddWicketOpen = () => setAddWicketsOpen(true);
  const handleAddWicketClose = () => setAddWicketsOpen(false);

  const handleWifiSettingsOpen = (wicket: any) => {
    dispatch(getOneDeviceData(wicket.device._id));
    setWifiSettings(true);
  };
  const handleWifiSettingsClose = () => setWifiSettings(false);

  const handleCameraSettingsOpen = () => {
    formik.setFieldValue("videoDuration", Math.random() * 1000);
    formik.setFieldValue("skipAudioValue", Math.random() * 1000);
    formik.setFieldValue("videoOutput", Math.random() * 1000);
    formik.setFieldValue("default_bitrate", Math.round(Math.random() * 4000));
    formik.setFieldValue(
      "default_brightness",
      Math.round(Math.random() * 4000)
    );
    setCameraSettings(true);
  };
  const handleCameraSettingsClose = () => setCameraSettings(false);

  const initialValues: any = {
    wifiSettings: [
      {
        ssid: "",
        password: "",
      },
    ],
  };

  const handleSubmit = (values: any) => {
    const payload = {
      wifi_settings: values?.wifi_settings,
    };
    setWifiSettings(false);
    if (getOneDevice) {
      dispatch(getUpdateDeviceData(getOneDevice?._id, payload));
      ToastService.success("Wifi settings updated");
    } else {
      ToastService.error("Failed to update wifi, Please try again later");
    }
  };

  const handlePlay = (event: any, pairId: string, id: string) => {
    const payload = {
      is_reserve: event,
    };

    dispatch(getUpdateDeviceData(id, payload));

    setChecklist((prevItems: any[]) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, reserve: event } : item
      )
    );

    const newData = pairDataForTable.map((item) =>
      item._id === pairId
        ? { ...item, device: { ...item.device, is_reserve: event } }
        : item
    );
    console.log(newData);
    setPairDataForTable(newData);
  };

  const handleAddNewService = (arrayHelpers: any) => {
    arrayHelpers.push(defaultWifiSettingObject);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });

  const initialChannelValues = {
    type: "",
    channelReference: "",
    streamKey: "",
    ingestEndpoint: "",
    playbackUrl: "",
    _id: "",
  };

  const handleStreamKeyUpdate = (event: any, channelData: any) => {
    const newStreamKey = event.target.value;
    const updatedChannelDetails = {
      streamKey: newStreamKey,
    };
    dispatch(updateChannelDetail(channelData?._id, updatedChannelDetails));
    ToastService.success("Stream key updated");
  };

  const handleCameraSettings = (event: any) => {
    event.preventDefault();
    const form = event.target;
    ToastService.error("This feature is working in progress");
    handleCameraSettingsClose();
  };

  useEffect(() => {
    if (
      unReservedChannel &&
      unReservedChannel.length > 0 &&
      formik.values.camera1
    ) {
      setRemainingChannel(
        unReservedChannel.filter((o: any) => {
          return o._id !== formik.values.camera1;
        })
      );
    }
  }, [formik.values.camera1]);

  const handleAddWicket = (event: any) => {
    event.preventDefault();
    const form = event.target;
    const device = form.device.value;
    const camera1 = form.camera1.value;
    const brightness1 = form.brightness1.value;
    const withBalance1 = form.withBalance1.value;
    const camera2 = form.camera2.value;
    const brightness2 = form.brightness2.value;
    const withBalance2 = form.withBalance2.value;

    const payload = {
      device: device,
      camera1: {
        channelId: camera1,
        bitrate: brightness1,
        whiteBalance: withBalance1,
      },
      camera2: {
        channelId: camera2,
        bitrate: brightness2,
        whiteBalance: withBalance2,
      },
    };
    dispatch(addPairDetail(payload));
    ToastService.success("Wicket Successfully Added");
    handleAddWicketClose();
  };

  function updateChecklist() {
    const newChecklist = pairDetail
      .map((row) => {
        const foundItem = deviceData?.find(
          (data) => data._id === row.device._id
        );
        if (foundItem) {
          return {
            id: foundItem._id,
            reserve: foundItem.is_reserve,
          };
        }
        return undefined;
      })
      .filter((item) => item !== undefined); // Filters out undefined values and assures TypeScript of the type

    setChecklist((prevItems: any) => [...newChecklist]);
  }

  useEffect(() => {
    updateChecklist();
  }, [initialLoading, pairDetailLoading]);

  // const findReserveById = (id: string) => {
  //   const reserveEntry = checklist.find((item: any) => item.id === id);
  //   return reserveEntry ? reserveEntry.reserve : false;
  // };

  useEffect(() => {
    if (deviceData.length === checklist.length) {
      setEmptyDeviceMessage(true);
    } else {
      setEmptyDeviceMessage(false);
    }
  }, [checklist]);

  // console.log(pairDetail);

  return (
    <>
      <div className="mainPageHeading">
        <h1
          style={{
            color: themeData === "dark" ? "white" : "",
            marginLeft: sidebarToggle ? "180px" : "",
          }}
          className={`${
            theme.palette.mode === "light" ? "login-text-dark" : ""
          } pageHeading`}
        >
          Wickets
        </h1>
        <div className="pageHeadingButtons">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            style={{ width: "260px" }}
            onClick={handleAddWicketOpen}
            className="loginButton"
          >
            <AddIcon />
            Add Wicket
          </Button>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            onClick={() => console.log("delete clicked")}
            className="deleteButton"
          >
            Delete
          </Button>
        </div>
      </div>

      {pairDetailLoading || initialLoading || channelDetailLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <p
                        className={`${
                          theme.palette.mode === "light"
                            ? "login-text-dark"
                            : ""
                        } tableHead`}
                      >
                        Wickets
                      </p>
                    </TableCell>
                    <TableCell>
                      <p
                        className={`${
                          theme.palette.mode === "light"
                            ? "login-text-dark"
                            : ""
                        } tableHead`}
                      >
                        Wifi Settings
                      </p>
                    </TableCell>
                    <TableCell>
                      <p
                        className={`${
                          theme.palette.mode === "light"
                            ? "login-text-dark"
                            : ""
                        } tableHead`}
                      >
                        Camera Feed
                      </p>
                    </TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <p
                        className={`${
                          theme.palette.mode === "light"
                            ? "login-text-dark"
                            : ""
                        } tableHead`}
                      >
                        Stream key
                      </p>
                      <span>
                        <InfoOutlinedIcon
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onMouseEnter={handleHelpOpen}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <p
                        className={`${
                          theme.palette.mode === "light"
                            ? "login-text-dark"
                            : ""
                        } tableHead`}
                      >
                        Broadcast Controls
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pairDetail &&
                    pairDataForTable &&
                    pairDataForTable.length > 0 &&
                    pairDataForTable.map((row: any, index: any) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const channelDetails1 =
                        channelDetail.find(
                          (data) => data?._id === row.camera1._id
                        ) || initialChannelValues;
                      const channelDetails2 =
                        channelDetail.find(
                          (data) => data?._id === row.camera2._id
                        ) || initialChannelValues;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              sx={{
                                color: "#A8AAAE",
                              }}
                              color="primary"
                              value={row._id}
                              onChange={() => handleChange(row._id)}
                              checked={
                                selectedPair.includes(row._id) ? true : false
                              }
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              {row.device.deviceName
                                ? row.device.deviceName
                                : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <Button
                              color="primary"
                              variant="outlined"
                              type="submit"
                              onClick={() => handleWifiSettingsOpen(row)}
                              sx={{
                                borderColor: `${
                                  theme.palette.mode === "dark"
                                    ? "primary.light"
                                    : "primary.main"
                                }`,
                                color: `${
                                  theme.palette.mode === "dark"
                                    ? "primary.light"
                                    : "primary.main"
                                }`,
                                "&:hover": {
                                  borderColor: `${
                                    theme.palette.mode === "dark"
                                      ? "primary.dark"
                                      : "primary.main"
                                  }`,
                                  color: `${
                                    theme.palette.mode === "dark"
                                      ? "primary.dark"
                                      : "primary.main"
                                  }`,
                                },
                              }}
                            >
                              see wifi settings
                            </Button>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <div
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows`}
                            >
                              <p className="wicketCamCell">
                                Camera 1
                                <SettingsIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={handleCameraSettingsOpen}
                                />
                              </p>
                              <p className="wicketCamCell">
                                Camera 2
                                <SettingsIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={handleCameraSettingsOpen}
                                />
                              </p>
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <div
                              className={`${
                                theme.palette.mode === "light"
                                  ? "login-text-dark"
                                  : ""
                              } tableRows flexRow`}
                            >
                              <TextField
                                id={`streamKey-channel1-${row._id}`}
                                defaultValue={channelDetails1.streamKey}
                                size="small"
                                placeholder="Enter stream key for channel 1"
                                onBlur={(event) =>
                                  handleStreamKeyUpdate(event, channelDetails1)
                                }
                              />
                              <TextField
                                id={`streamKey-channel2-${row._id}`}
                                defaultValue={channelDetails2.streamKey}
                                size="small"
                                placeholder="Enter stream key for channel 2"
                                onBlur={(event) =>
                                  handleStreamKeyUpdate(event, channelDetails2)
                                }
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography>Pause</Typography>
                              <FormControl>
                                <Switch
                                  checked={
                                    row?.device?.is_reserve ? false : true
                                  }
                                  onChange={(event) =>
                                    handlePlay(
                                      !row?.device?.is_reserve,
                                      row?._id,
                                      row.device._id
                                    )
                                  }
                                  color="primary"
                                />
                              </FormControl>
                              <Typography>Play</Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {wickets && wickets.length === 0 && (
              <span
                className={`${
                  theme.palette.mode === "light" ? "login-text-dark" : ""
                } no-data-found tableRows`}
              >
                No Wickets Settings Found
              </span>
            )}
          </Paper>
        </Box>
      )}

      {/*wifi edit dialogue  */}
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={wifiSettings}
        onClose={handleWifiSettingsClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="modalHead">
            <span>Wifi Settings</span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleWifiSettingsClose}
            />
          </div>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <FormikProvider value={formik}>
              {getOneDeviceLoading ? (
                <div className="spinnerDiv">
                  <CircularProgress />
                </div>
              ) : (
                <Form>
                  <FieldArray
                    name="wifi_settings"
                    render={(arrayHelpers) => (
                      <>
                        <div className="fieldAddBtn">
                          <Button
                            color="primary"
                            variant="outlined"
                            type="button"
                            onClick={() => handleAddNewService(arrayHelpers)}
                            sx={{
                              borderColor: `${
                                theme.palette.mode === "dark"
                                  ? "primary.light"
                                  : "primary.main"
                              }`,
                              color: `${
                                theme.palette.mode === "dark"
                                  ? "primary.light"
                                  : "primary.main"
                              }`,
                              "&:hover": {
                                borderColor: `${
                                  theme.palette.mode === "dark"
                                    ? "primary.dark"
                                    : "primary.main"
                                }`,
                                color: `${
                                  theme.palette.mode === "dark"
                                    ? "primary.dark"
                                    : "primary.main"
                                }`,
                              },
                            }}
                          >
                            Add Wi-Fi Network
                          </Button>
                        </div>
                        {formik.values.wifi_settings &&
                          formik.values.wifi_settings.length > 0 &&
                          formik.values.wifi_settings.map(
                            (o: WifiSettingSchema, index: number) => (
                              <React.Fragment key={index}>
                                <Accordion sx={{ marginBottom: "20px" }}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    {o.ssid || "new wifi"}
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      id={`wifi_settings[${index}].ssid`}
                                      name={`wifi_settings[${index}].ssid`}
                                      label="SSID"
                                      placeholder="Enter SSID"
                                      type={"text"}
                                      value={o.ssid}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      style={{ marginTop: "20px" }}
                                    />
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      id={`wifi_settings[${index}].password`}
                                      name={`wifi_settings[${index}].password`}
                                      label="Password"
                                      placeholder="Enter password"
                                      type={"text"}
                                      value={o.password}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      style={{ marginTop: "20px" }}
                                    />
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      type="button"
                                      size="small"
                                      onClick={() => arrayHelpers.remove(index)}
                                      sx={{
                                        marginY: 2,
                                        float: "right",
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </AccordionDetails>
                                </Accordion>
                              </React.Fragment>
                            )
                          )}
                      </>
                    )}
                  />
                </Form>
              )}
            </FormikProvider>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "center",
              position: "sticky",
              bottom: "0",
              backgroundColor: `${
                theme.palette.mode === "dark" ? "#383838" : "white"
              }`,
              zIndex: "10",
              paddingBottom: "20px",
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              sx={{
                borderColor: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                color: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                "&:hover": {
                  borderColor: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                  color: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                },
              }}
              type="button"
              size="large"
              style={{ width: "270px" }}
              className="loginButton"
              onClick={handleWifiSettingsClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              style={{ width: "270px" }}
              type="submit"
              size="large"
              className="loginButton"
            >
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* camera settings dialog */}
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={cameraSettings}
        onClose={handleCameraSettingsClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="modalHead">
            <span>Wifi Settings</span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleCameraSettingsClose}
            />
          </div>
        </DialogTitle>
        <form onSubmit={handleCameraSettings}>
          <DialogContent>
            <TextField
              fullWidth
              variant="outlined"
              id="videoDuration"
              name="videoDuration"
              label="videoDuration"
              placeholder="Enter videoDuration"
              type="text"
              value={formik.values.videoDuration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ marginTop: "20px" }}
            />
            <TextField
              fullWidth
              variant="outlined"
              id="skipAudioValue"
              name="skipAudioValue"
              label="skipAudioValue"
              placeholder="Enter skipAudioValue"
              type="text"
              value={formik.values.skipAudioValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ marginTop: "20px" }}
            />
            <TextField
              fullWidth
              variant="outlined"
              id="videoOutput"
              name="videoOutput"
              label="videoOutput"
              placeholder="Enter videoOutput"
              type="text"
              value={formik.values.videoOutput}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ marginTop: "20px" }}
            />
            <InputLabel id="demo-simple-select-error-label" sx={{ mt: 5 }}>
              Bitrate
            </InputLabel>
            <Slider
              aria-label="default_bitrate"
              name="default_bitrate"
              defaultValue={2000}
              value={formik.values.default_bitrate}
              onChange={formik.handleChange}
              valueLabelDisplay="on"
              step={100}
              min={1000}
              max={4000}
            />
            <InputLabel id="demo-simple-select-error-label" sx={{ mt: 2 }}>
              Brightness
            </InputLabel>
            <Slider
              aria-label="default_brightness"
              name="default_brightness"
              defaultValue={2000}
              value={formik.values.default_brightness}
              onChange={formik.handleChange}
              valueLabelDisplay="on"
              step={100}
              min={1000}
              max={4000}
            />
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "center",
              position: "sticky",
              bottom: "0",
              backgroundColor: `${
                theme.palette.mode === "dark" ? "#383838" : "white"
              }`,
              zIndex: "10",
              paddingBottom: "20px",
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              sx={{
                borderColor: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                color: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                "&:hover": {
                  borderColor: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                  color: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                },
              }}
              type="button"
              size="large"
              style={{ width: "270px" }}
              className="loginButton"
              onClick={handleCameraSettingsClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              style={{ width: "270px" }}
              type="submit"
              size="large"
              className="loginButton"
            >
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* add wickets modal */}
      <Dialog
        fullWidth
        open={addWicketsOpen}
        onClose={handleAddWicketClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="modalHead">
            <span>Add Wicket</span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleAddWicketClose}
            />
          </div>
        </DialogTitle>
        <form onSubmit={handleAddWicket}>
          <DialogContent>
            {addPairError && <Alert severity="error">{addPairError}</Alert>}
            <FormControl
              fullWidth
              style={{ marginTop: "20px" }}
              error={formik.touched.device && Boolean(formik.errors.device)}
            >
              <InputLabel id="demo-simple-select-error-label">
                Device
              </InputLabel>
              <Select
                labelId="demo-simple-select-error-label"
                id="device"
                value={formik.values.device}
                label="Device"
                name="device"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {/* {deviceData.map((device) => (
                  <MenuItem key={device._id} value={device._id}>
                    {device?.deviceName}
                  </MenuItem>
                ))} */}
                {deviceData
                  .filter(
                    (device) =>
                      !checklist.some(
                        (checkItem: any) => checkItem.id === device._id
                      )
                  )
                  .map((device) => (
                    <MenuItem key={device._id} value={device._id}>
                      {device.deviceName}
                    </MenuItem>
                  ))}
                {emptyDeviceMessage && (
                  <MenuItem>No Free Device Available</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              style={{ marginTop: "20px" }}
              error={formik.touched.camera1 && Boolean(formik.errors.camera1)}
            >
              <InputLabel id="demo-simple-select-error-label">
                Camera 1
              </InputLabel>
              <Select
                labelId="demo-simple-select-error-label"
                id="camera1"
                value={formik.values.camera1}
                label="Camera 1"
                name="camera1"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {unReservedChannel.map((o) => (
                  <MenuItem key={o._id} value={o._id}>
                    {o.channelReference}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid style={{ marginTop: "20px" }} container spacing={2}>
              <Grid item xs={6}>
                <InputLabel id="demo-simple-select-error-label">
                  Bitrate
                </InputLabel>
                <Slider
                  aria-label="Temperature"
                  name="brightness1"
                  defaultValue={2000}
                  value={formik.values.brightness1}
                  onChange={formik.handleChange}
                  valueLabelDisplay="on"
                  step={100}
                  min={1000}
                  max={4000}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel id="demo-simple-select-error-label">
                  White Balance
                </InputLabel>
                <Slider
                  aria-label="Temperature"
                  defaultValue={2000}
                  name="withBalance1"
                  value={formik.values.withBalance1}
                  onChange={formik.handleChange}
                  valueLabelDisplay="on"
                  step={100}
                  min={1000}
                  max={4000}
                />
              </Grid>
            </Grid>

            <FormControl
              fullWidth
              style={{ marginTop: "20px" }}
              error={formik.touched.camera2 && Boolean(formik.errors.camera2)}
            >
              <InputLabel id="demo-simple-select-error-label">
                Camera 2
              </InputLabel>
              <Select
                labelId="demo-simple-select-error-label"
                id="demo-simple-select-error"
                name="camera2"
                value={formik.values.camera2}
                label="Camera 2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {remainingChannel &&
                  remainingChannel.length > 0 &&
                  remainingChannel.map((o: ChannelSchema) => (
                    <MenuItem key={o._id} value={o._id}>
                      {o.channelReference}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Grid style={{ marginTop: "20px" }} container spacing={2}>
              <Grid item xs={6}>
                <InputLabel id="demo-simple-select-error-label">
                  Bitrate
                </InputLabel>
                <Slider
                  aria-label="Temperature"
                  defaultValue={2000}
                  name="brightness2"
                  value={formik.values.brightness2}
                  onChange={formik.handleChange}
                  valueLabelDisplay="on"
                  step={100}
                  min={1000}
                  max={4000}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel id="demo-simple-select-error-label">
                  White Balance
                </InputLabel>
                <Slider
                  aria-label="Temperature"
                  defaultValue={2000}
                  name="withBalance2"
                  value={formik.values.withBalance2}
                  onChange={formik.handleChange}
                  valueLabelDisplay="on"
                  step={100}
                  min={1000}
                  max={4000}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ marginBottom: "20px", justifyContent: "center" }}
          >
            <Button
              color="primary"
              variant="outlined"
              sx={{
                borderColor: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                color: `${
                  theme.palette.mode === "dark"
                    ? "primary.light"
                    : "primary.main"
                }`,
                "&:hover": {
                  borderColor: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                  color: `${
                    theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                  }`,
                },
              }}
              type="button"
              size="large"
              style={{ width: "270px" }}
              className="loginButton"
              disabled={addPairLoading}
              onClick={handleAddWicketClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              style={{ width: "270px" }}
              type="submit"
              size="large"
              disabled={addPairLoading}
              // onClick={handleSubmit}
              className="loginButton"
            >
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* help dialogue */}
      <Dialog
        fullScreen={fullScreen}
        open={helpOpen}
        onClose={handleHelpClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <img
            src={streamHelp}
            alt="stream key find"
            style={{ width: "100%" }}
          />
          <p className="helpHead">How To Find Your Stream Key</p>
          <ol type="1">
            <li>Go to your YouTube Live Dashboard.</li>
            <li>
              Click on the Settings gear icon, usually found on the right side.
            </li>
            <li>Select the Stream tab.</li>
            <li>
              Your Stream Key will be visible under the Stream Settings section.
              It's a long sequence of letters and numbers.
            </li>
          </ol>
          <p className="helpBottom">
            Please copy your Stream Key from here and paste it into the 'Stream
            Key' input field of the 'Wickets' table in our Bond Cam platform.
          </p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleHelpClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Wickets;
