/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../config/store";
import { getAllUserDetails } from "../middleware/users";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { dateWithMonthFormat } from "../../../helper/dateFormate";
import info from "../../../assets/images/info-circle.svg";
import deleteBox from "../../../assets/images/trash.svg";
import { useNavigate } from "react-router-dom";
import ROUTE_URLS from "../../../config/routes";
import { resetUserDetails } from "../slices/userSlice";
import LocalstorageService from "../../../helper/localstorage-services";
import { Visibility } from "@mui/icons-material";

interface Data {
  date_registered: string;
  serialCode: string;
  user: string;
  deviceName: string;
  status: string;
  action: HTMLElement;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "date_registered",
    numeric: false,
    disablePadding: false,
    label: "Date Registered",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    label: "User",
  },
  {
    id: "deviceName",
    numeric: false,
    disablePadding: false,
    label: "Device Name",
  },
  {
    id: "serialCode",
    numeric: false,
    disablePadding: false,
    label: "Serial",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

const UserManagement = () => {
  const dispatch: AppDispatch = useDispatch();
  const userInformation = LocalstorageService.getLoggedInUserDetails();
  const themeData = LocalstorageService.getThemeMode();
  const navigate = useNavigate();

  const { sidebarToggle } = useSelector((state: RootState) => state.users);

  // view as user function
  const changleRole = (user_id: string) => {
    const userData = { ...userInformation, role: "user", _id: user_id };
    LocalstorageService.setLoggedInUserDetails(userData);
    navigate(ROUTE_URLS.DEVICE_MANAGEMENT);
  };

  const { userLoading, userDetails } = useSelector(
    (state: RootState) => state.users
  );
  const nav = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!userLoading && initialLoading && userDetails) {
      setInitialLoading(false);
    }
    // eslint-disable-next-line
  }, [userDetails]);

  useEffect(() => {
    dispatch(resetUserDetails());
    dispatch(getAllUserDetails({ page: page, limit: 10 }));
    // eslint-disable-next-line
  }, [page]);

  const handleChangePage = (event: unknown, value: number) => {
    setPage(value);
  };

  const handleEdit = (user_id: string) => {
    nav(`${ROUTE_URLS.USER_DETAIL}/${user_id}`);
  };

  return (
    <div>
      <div className="mainPageHeading">
        <h1
          style={{
            color: themeData === "dark" ? "white" : "",
            marginLeft: sidebarToggle ? "180px" : "",
          }}
          className="pageHeading"
        >
          Users
        </h1>
        <div className="pageHeadingButtons">
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            // onClick={() => {
            //   setOpenDeleteDialog(true);
            // }}
            className="deleteButton"
          >
            Delete
          </Button>
        </div>
      </div>

      {userLoading && initialLoading ? (
        <div className="spinnerDiv">
          <CircularProgress />
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                      >
                        <span className="tableHead">{headCell.label}</span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userDetails &&
                    userDetails.data.length > 0 &&
                    userDetails.data.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              sx={{
                                color: "#A8AAAE",
                              }}
                              color="primary"
                              value={row._id}
                              // onChange={() => handleChange(row._id, 'multiple')}
                              // checked={selected.includes(row._id) ? true : false}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p className="tableRows">
                              {row.createdAt
                                ? dateWithMonthFormat(row.createdAt)
                                : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p className="tableRows">
                              {row.firstName
                                ? `${row.firstName} ${row.lastName}`
                                : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p className="tableRows ellipseText">
                              {row.devices && row.devices.length > 0
                                ? row.devices.map((device) => (
                                    <p key={device._id}>{device.deviceName}</p>
                                  ))
                                : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <p className="tableRows ellipseText">
                              {row.devices && row.devices.length > 0
                                ? row.devices.map((device) => (
                                    <p key={device._id}>{device.serial}</p>
                                  ))
                                : "- - - - -"}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            {row.status === "active" ? (
                              <p style={{ color: "#28C76F" }}>Active</p>
                            ) : (
                              <p style={{ color: "#EA5455" }}>Inactive</p>
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <img
                                  onClick={() => handleEdit(row._id)}
                                  src={info}
                                  alt="edit"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <img src={deleteBox} alt="delete" />
                              </Grid>
                              <Grid item xs={3}>
                                <div
                                  title={`View as ${row.firstName}`}
                                  onClick={() => {
                                    changleRole(row._id);
                                  }}
                                >
                                  <Visibility></Visibility>
                                </div>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pagination">
              <p className="tableRows">{`Showing ${userDetails?.metadata.page} to ${userDetails?.metadata.limit} of ${userDetails?.metadata.count} entries`}</p>
              <Pagination
                onChange={handleChangePage}
                count={Math.ceil(
                  userDetails?.metadata.limit && userDetails?.metadata.count
                    ? userDetails?.metadata.count / userDetails?.metadata.limit
                    : 1
                )}
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </div>
            {userDetails?.data && userDetails?.data.length === 0 && (
              <span className="no-data-found tableRows">No Devices Found</span>
            )}
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default UserManagement;
