export const BASE_URL = "/";
const ROUTE_URLS = {
	DASHBOARD: `${BASE_URL}`,
	LOGIN: `${BASE_URL}login`,
	SIGNUP: `${BASE_URL}sign-up`,
	FORGOT_PASSWORD: `${BASE_URL}forgot-password`,
	RESET_PASSWORD: `${BASE_URL}reset-password`,
	HOME: `${BASE_URL}home`,
	UPDATE_PROFILE: `${BASE_URL}update-profile`,
	UPDATE_PASSWORD: `${BASE_URL}update-password`,
	DEVICE_MANAGEMENT: `${BASE_URL}devices`,
	PAIRS_MANAGEMENT: `${BASE_URL}pairs`,
	CHANNEL_MANAGEMENT: `${BASE_URL}channel`,
	CHANNEL_HISTORY: `${BASE_URL}channel_history`,
	BROADCAST_MANAGEMENT: `${BASE_URL}broadcast`,
	BROADCAST_STREAM: `${BASE_URL}broadcast`,
	BROADCAST_HISTORY: `${BASE_URL}broadcast_history`,
	USERS: `${BASE_URL}users`,
	USER_DETAIL: `${BASE_URL}users`,
	ANALYTICS: `${BASE_URL}analytics`,
	CHANNEL_MANAGE: `${BASE_URL}channel_management`,
	RECENT_BROADCAST: `${BASE_URL}broadcast_recent`,
	WICKETS: `${BASE_URL}wickets`,
};

export default ROUTE_URLS;
