import SettingsIcon from "@mui/icons-material/Settings";
import ROUTE_URLS from "../../config/routes";
import BroadcastGrey from "../../assets/images/sidebar/broadCastGrey.svg";
import BroadcastWhite from "../../assets/images/sidebar/broadCastWhite.svg";
import ChannelGrey from "../../assets/images/sidebar/channelGrey.svg";
import ChannelWhite from "../../assets/images/sidebar/channelWhite.svg";
import DeviceGrey from "../../assets/images/sidebar/devicesGrey.svg";
import DeviceWhite from "../../assets/images/sidebar/devicesWhite.svg";
import AnalyticsGrey from "../../assets/images/sidebar/analysticGrey.svg";
import AnalyticsWhite from "../../assets/images/sidebar/analysticWhite.svg";
import UserGrey from "../../assets/images/sidebar/usersGrey.svg";
import UserWhite from "../../assets/images/sidebar/usersWhite.svg";
// import PairGrey from "../../assets/images/sidebar/pairsGrey.svg";
// import PairWhite from "../../assets/images/sidebar/pairsWhite.svg";

interface Menus {
	title: string;
	iconGrey: string;
	iconWhite: string;
	icon: any;
	link: string;
	role: string;
}

export type MenusInterface = Menus[];

export const SidebarMenu: MenusInterface = [
	{
		title: "Devices",
		icon: "DevicesIcon",
		iconGrey: DeviceGrey,
		iconWhite: DeviceWhite,
		link: ROUTE_URLS.DEVICE_MANAGEMENT,
		role: "user",
	},
	{
		title: "Channel",
		icon: "CameraAltOutlined",
		iconGrey: ChannelGrey,
		iconWhite: ChannelWhite,
		link: ROUTE_URLS.CHANNEL_MANAGEMENT,
		role: "user",
	},
	// {
	// 	title: "Pairs",
	// 	icon: "Merge",
	// 	iconGrey: PairGrey,
	// 	iconWhite: PairWhite,
	// 	link: ROUTE_URLS.PAIRS_MANAGEMENT,
	// 	role: "user",
	// },
	// {
	// 	title: "BroadCast",
	// 	icon: "VideocamIcon",
	// 	iconGrey: BroadcastGrey,
	// 	iconWhite: BroadcastWhite,
	// 	link: ROUTE_URLS.BROADCAST_MANAGEMENT,
	// 	role: "user",
	// },
	{
		title: "Wickets",
		icon: "VideocamIcon",
		iconGrey: BroadcastGrey,
		iconWhite: BroadcastWhite,
		link: ROUTE_URLS.WICKETS,
		role: "user",
	},
	{
		title: "Users",
		icon: "CameraAltOutlined",
		iconGrey: UserGrey,
		iconWhite: UserWhite,
		link: ROUTE_URLS.USERS,
		role: "admin",
	},
	{
		title: "Analytics",
		icon: "DevicesIcon",
		iconGrey: AnalyticsGrey,
		iconWhite: AnalyticsWhite,
		link: ROUTE_URLS.ANALYTICS,
		role: "admin",
	},
	{
		title: "Channels",
		icon: "CameraAltOutlined",
		iconGrey: ChannelGrey,
		iconWhite: ChannelWhite,
		link: ROUTE_URLS.CHANNEL_MANAGE,
		role: "admin",
	},
];

export const settings: MenusInterface = [
	{
		title: "Setting",
		link: ROUTE_URLS.UPDATE_PROFILE,
		iconGrey: "",
		iconWhite: "",
		icon: SettingsIcon,
		role: "any",
	},
];
