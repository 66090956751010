import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../config/store";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../../helper";
import ReactPlayer from "react-player";
import { Box, Button, CircularProgress } from "@mui/material";
import LocalstorageService from "../../../helper/localstorage-services";
import ROUTE_URLS from "../../../config/routes";
import { useNavigate, useParams } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import { getBroadcastRecordings } from "../middleware/broadcast";

const RecentBroadcast = () => {
  const { bucketNames, broadRecordings } = useSelector(
    (state: RootState) => state.broadcast
  );
  const { sidebarToggle } = useSelector((state: RootState) => state.users);
  const [recentRecordings, setRecentRecordings] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedIndex, setExpandedIndex] = useState(0);

  const themeData = LocalstorageService.getThemeMode();
  const nav = useNavigate();
  const { broadcast_id } = useParams();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getBroadcastRecordings(`broadcastId=${broadcast_id}`));
  }, []);

  useEffect(() => {
    const fetchDataForBuckets = async () => {
      try {
        const apiRequests = bucketNames.map(async (bucketName) => {
          const split = bucketName.split("/");
          const response = await get(`recordings/?bucketName=${split[1]}`);
          return `https://${split[0]}/${response.data[0]}`;
        });
        // Use Promise.all to wait for all promises to resolve
        const results = await Promise.all(apiRequests);
        setRecentRecordings(results);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data for buckets:", error);
        setLoading(false);
      }
    };
    fetchDataForBuckets();
  }, [bucketNames]);

  const toggleSize = (index: number) => {
    if (index === expandedIndex) {
      return;
    }
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  const handlePlayerClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className="mainPageHeading">
        <h1
          style={{
            color: themeData === "dark" ? "white" : "",
            marginLeft: sidebarToggle ? "180px" : "",
          }}
          className={`${
            themeData === "light" ? "login-text-dark" : ""
          } pageHeading`}
        >
          Latest Preview
        </h1>
        <div className="pageHeadingButtons">
          {broadRecordings.length > 0 ? (
            <>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => {
                  nav(`${ROUTE_URLS.BROADCAST_HISTORY}/${broadcast_id}/0`);
                }}
                className="recordingButton"
              >
                See Previous recordings
                <HistoryIcon />
              </Button>
            </>
          ) : (
            " "
          )}
        </div>
      </div>
      <Box sx={{ width: { xs: "100%", md: "60%" }, margin: "0px auto" }}>
        {loading ? (
          <div className="spinnerDiv">
            <CircularProgress />
          </div>
        ) : (
          <div className="video-grid">
            {recentRecordings &&
              recentRecordings.length > 0 &&
              recentRecordings.map((url, index) => (
                <div
                  key={index}
                  className={`video-item ${
                    expandedIndex === index ? "expanded" : ""
                  }`}
                  onClick={() => toggleSize(index)}
                >
                  <ReactPlayer
                    url={url}
                    width="100%"
                    height="100%"
                    playing={true}
                    onClick={(e: { preventDefault: () => void }) =>
                      handlePlayerClick(e)
                    }
                    onError={(error) => {
                      // console.log(error);
                    }}
                    controls={expandedIndex === index ? true : false}
                    muted
                  />
                </div>
              ))}
          </div>
        )}
      </Box>
    </div>
  );
};

export default RecentBroadcast;
