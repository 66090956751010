import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { SidebarMenu, settings } from "./data";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import ROUTE_URLS from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../profile/middleware/profile";
import { AppDispatch, RootState } from "../../config/store";
import ToastService from "../../helper/toast-services";
import { resetLogoutUser } from "../profile/slices/profileSlice";
import styled from "@emotion/styled";
import LocalstorageService from "../../helper/localstorage-services";
import sidebarLogo from "../../assets/images/sidebar/sideBarLogo.svg";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { toggleSidebar } from "../UsersManagement/slices/userSlice";

const drawerWidth = 240;

interface Props {
  window?: () => Window;
  setThemeMode: (value: boolean) => void;
  themeMode: boolean;
}
const LayoutUpdate = (props: Props) => {
  const location = useLocation();
  const theme = useTheme();
  const { window, setThemeMode, themeMode } = props;
  const { sidebarToggle } = useSelector((state: RootState) => state.users);
  const dispatch: AppDispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [activeTab, setActiveTab] = React.useState<string>("");
  const userRole = LocalstorageService.getLoggedInUserDetails();
  const adminRole = LocalstorageService.getLoggedInAdminDetails();
  const userData = LocalstorageService.getLoggedInUserDetails();

  React.useEffect(() => {
    const themeData = LocalstorageService.getThemeMode();
    if (themeData && themeData !== null) {
      setThemeMode(themeData === "dark" ? true : false);
    }
  });

  const { logoutUserDetails, logoutUserError } = useSelector(
    (state: RootState) => state.profile
  );
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // view as admin function
  const changleRole = (user_id: string) => {
    const userData = { ...userRole, role: "admin", _id: user_id };
    LocalstorageService.setLoggedInUserDetails(userData);
    navigate(ROUTE_URLS.USERS);
  };

  React.useEffect(() => {
    setActiveTab(location.pathname);
  });

  React.useEffect(() => {
    if (logoutUserDetails && !logoutUserError) {
      ToastService.success(logoutUserDetails);
      dispatch(resetLogoutUser());
      navigate(ROUTE_URLS.LOGIN);
    } else if (!logoutUserDetails && logoutUserError) {
      ToastService.error(logoutUserError);
      dispatch(resetLogoutUser());
    }
    // eslint-disable-next-line
  }, [logoutUserDetails, logoutUserError]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const drawer = (
    <List className="sidebarNav">
      <Link
        style={{
          display: "flex",
          gap: "10px",
          fontSize: "1.25rem",
          fontWeight: "600",
          alignItems: "center",
          margin: "5px 0px 25px 9px",
        }}
        to={ROUTE_URLS.DASHBOARD}
        className={`${
          theme.palette.mode === "dark"
            ? "link-format-white"
            : "link-format-black"
        } link-format`}
      >
        <img src={sidebarLogo} alt="Logo" />
        Bond Cam
      </Link>
      <Divider />
      {SidebarMenu.map((list, index) => (
        <React.Fragment key={index}>
          {userRole?.role === list.role && (
            <Link
              to={list.link}
              className={`${
                theme.palette.mode === "dark"
                  ? "link-format-white"
                  : "link-format-black"
              } link-format`}
              onClick={() => setActiveTab(list.link)}
            >
              <div
                className={`${activeTab.includes(list.link) && "activeTab"}`}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {activeTab.includes(list.link) ? (
                        <img src={list.iconWhite} alt={list.iconWhite} />
                      ) : (
                        <img src={list.iconGrey} alt={list.iconGrey} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={list.title} />
                  </ListItemButton>
                </ListItem>
              </div>
            </Link>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  const MaterialUISwitch = styled(Switch)(({ theme }: any) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be", //   "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), var(--light-solid-color-primary-500-base, #6D3190)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const handleThemeModeChange = (
    e: React.ChangeEvent<HTMLInputElement | any>
  ) => {
    setThemeMode(e.target.checked);
    LocalstorageService.setThemeMode(
      e.target.checked === true ? "dark" : "light"
    );
  };

  return (
    <div>
      {sidebarToggle ? (
        <div
          style={{
            cursor: "pointer",
            position: "sticky",
            top: "94.8%",
            left: "0px",
            width: "24px",
            backgroundColor: "white",
            color: "black",
            padding: "2px",
            height: "24px",
            zIndex: "500",
            borderRadius: "0px 6px 6px 0px",
            boxShadow: "0px 0px 6px 1px #afafaf",
          }}
          onClick={() => {
            dispatch(toggleSidebar(!sidebarToggle));
          }}
          className="toggleButton"
        >
          <ExitToAppIcon fontSize="medium" />
        </div>
      ) : (
        <div
          style={{
            cursor: "pointer",
            position: "sticky",
            top: "94.8%",
            left: "216px",
            width: "24px",
            color: "black",
            transform: "rotate(180deg)",
            zIndex: "500",
            backgroundColor: "white",
            padding: "2px",
            height: "24px",
            borderRadius: "0px 6px 6px 0px",
            boxShadow: "0px 0px 6px 1px #afafaf",
          }}
          onClick={() => {
            dispatch(toggleSidebar(!sidebarToggle));
          }}
          className="toggleButton"
        >
          <ExitToAppIcon fontSize="medium" />
        </div>
      )}
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          // overflowY: { xs: "scroll", sm: "none" },
        }}
      >
        <Box
          component="nav"
          className="navbarDesktop"
          sx={{
            width: `${drawerWidth}px`,
            flexShrink: { sm: 0 },
            display: { md: sidebarToggle ? "none" : "block", sm: "none" },
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            sx={{
              width: `${drawerWidth}px`,
              display: { xs: "none", sm: "block" }, //todo remove this to make custom css effective
              "& .MuiDrawer-paper": { boxSizing: "border-box" },
              ".MuiDrawer-paper ": {
                display: "flex",
                justifyContent: "space-between",
              },
            }}
            anchor="left"
            variant="permanent"
          >
            {drawer}
            <div>
              {userRole &&
                adminRole &&
                userRole?.role === "user" &&
                adminRole?.role === "admin" && (
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="small"
                    sx={{
                      margin: "5px auto",
                      width: "80%",
                      display: { xs: "none", sm: "block" },
                    }}
                    onClick={() => {
                      changleRole(adminRole._id);
                    }}
                  >
                    Switch To Admin
                  </Button>
                )}
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px 0px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    padding: "0px 12px",
                    ".MuiListItemIcon-root": {
                      minWidth: "56px",
                    },
                  }}
                >
                  {settings.map((setting, index) => (
                    <Link
                      key={index}
                      to={setting.link}
                      className={`${
                        theme.palette.mode === "dark"
                          ? "link-format-white"
                          : "link-format-black"
                      } link-format`}
                    >
                      <MenuItem style={{ display: "flex" }}>
                        <ListItemIcon>{<setting.icon />}</ListItemIcon>
                        <Typography textAlign="center">
                          {setting.title}
                        </Typography>
                      </MenuItem>
                    </Link>
                  ))}
                  <MenuItem
                    style={{ display: "flex" }}
                    onClick={() => handleLogout()}
                  >
                    <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      paddingLeft: "15px",
                    }}
                  >
                    <Typography textAlign="center">Theme</Typography>
                    <FormControlLabel
                      control={<MaterialUISwitch sx={{ m: 1 }} />}
                      checked={themeMode === true ? true : false}
                      value={themeMode}
                      onChange={handleThemeModeChange}
                      label=""
                    />
                  </div>
                </Box>
              </div>
            </div>
          </Drawer>
          {/* mobile sidebar  */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box" },
              ".MuiDrawer-paper ": {
                display: "flex",
                justifyContent: "space-between",
              },
            }}
          >
            {drawer}
            <div>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px 0px",
                }}
              >
                {userRole &&
                adminRole &&
                userRole?.role === "user" &&
                adminRole?.role === "admin" ? (
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="small"
                    style={{
                      width: "80%",
                    }}
                    onClick={() => {
                      changleRole(adminRole._id);
                    }}
                  >
                    Switch To Admin
                  </Button>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      padding: "0px 12px",
                      ".MuiListItemIcon-root": {
                        minWidth: "56px",
                      },
                    }}
                  >
                    {settings.map((setting, index) => (
                      <Link
                        key={index}
                        to={setting.link}
                        className={`${
                          theme.palette.mode === "dark"
                            ? "link-format-white"
                            : "link-format-black"
                        } link-format`}
                      >
                        <MenuItem style={{ display: "flex" }}>
                          <ListItemIcon>{<setting.icon />}</ListItemIcon>
                          <Typography textAlign="center">
                            {setting.title}
                          </Typography>
                        </MenuItem>
                      </Link>
                    ))}

                    <MenuItem
                      style={{ display: "flex" }}
                      onClick={() => handleLogout()}
                    >
                      <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        paddingLeft: "15px",
                      }}
                    >
                      <Typography textAlign="center">Theme</Typography>
                      <FormControlLabel
                        control={<MaterialUISwitch sx={{ m: 1 }} />}
                        checked={themeMode === true ? true : false}
                        value={themeMode}
                        onChange={handleThemeModeChange}
                        label=""
                      />
                    </div>
                  </Box>
                )}
              </div>
            </div>
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            width: "100%",
            marginTop: { xs: "0px", md: "-60px" },
          }}
        >
          <AppBar
            elevation={0}
            sx={{
              backgroundColor: {
                xs: theme.palette.mode === "dark" ? "#252629" : "#f8f7fa",
                md: "transparent",
              },
            }}
          >
            <Toolbar>
              <Link
                style={{
                  display: "flex",
                  gap: "10px",
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  alignItems: "center",
                }}
                to={ROUTE_URLS.DASHBOARD}
                className={`${
                  theme.palette.mode === "dark"
                    ? "link-format-white"
                    : "link-format-black"
                } link-format`}
              >
                <img src={sidebarLogo} alt="Logo" />
                Bond Cam
              </Link>
              <div style={{ flexGrow: "1" }}></div>
              {/* switch to admin button is fixed on the top right side  */}
              {/* {userRole &&
                adminRole &&
                userRole?.role === "user" &&
                adminRole?.role === "admin" && (
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="small"
                    sx={{
                      display: { xs: "none", sm: "block" },
                      width: "auto",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      changleRole(adminRole._id);
                    }}
                  >
                    Switch To Admin
                  </Button>
                )} */}
              <Box sx={{ flexGrow: 0, display: { xs: "none", md: "block" } }}>
                <Tooltip title="Open settings">
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar style={{ height: "40px", width: "40px" }}>
                        {`${userData?.firstName.charAt(
                          0
                        )}${userData?.lastName.charAt(0)}`}
                      </Avatar>
                    </IconButton>
                  </div>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting, index) => (
                    <Link
                      key={index}
                      to={setting.link}
                      className={`${
                        theme.palette.mode === "dark"
                          ? "link-format-white"
                          : "link-format-black"
                      } link-format`}
                    >
                      <div style={{ display: "flex", padding: "5px" }}>
                        <MenuItem onClick={handleCloseUserMenu}>
                          <ListItemIcon>{<setting.icon />}</ListItemIcon>
                          <Typography textAlign="center">
                            {setting.title}
                          </Typography>
                        </MenuItem>
                      </div>
                    </Link>
                  ))}
                  <div style={{ display: "flex", padding: "5px" }}>
                    <MenuItem onClick={() => handleLogout()}>
                      <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </div>
                </Menu>
              </Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  zIndex: "20000",
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  display: { sm: "block", md: "none" },
                }}
              >
                {mobileOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              py: 3,
              px: { xs: "12px", md: 3 },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default LayoutUpdate;
