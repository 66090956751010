import { createSlice } from "@reduxjs/toolkit";
import { LiveStatuses } from "../pages/Broadcast";

interface broadcastSchema {
	_id: string;
	title: string;
	status: boolean;
	pairs: BroadCastPairSchema;
	createdAt: string;
	broadcastHistory: any
}
export interface recordingSchema {
	_id: string;
	channelId: {
		_id: string;
		channelReference: string;
	};
	broadcastId: {
		_id: string;
		title: string;
	};
	vodURL: string;
	recordingStartedAt: string;
	playlistDuration: number | null;
	createdBy: string;
	updatedBy: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
}
interface BroadcastNewSchema {
	_id: string;
	title: string;
	status: boolean;
	pairs: string[];
	createdAt: string;
}

interface BroadCastPairInterFace {
	_id: string;
	device: string;
	camera1: string;
	camera2: string;
	created_by: string;
}

// type BroadCastPairSchema = BroadCastPairInterFace[];
type BroadCastPairSchema = any[];

export interface ChannelCameraSchema {
	_id: string;
	channelName: string;
	channelArn: string;
	secretArn: string;
	bitrate: number;
	whiteBalance: number;
	authorized: boolean;
	playbackUrl: string;
	streamKey: string;
	userId: string;
	status: string;
	createdAt: string;
	distributionDomainName: string
}

type PairSchema = ChannelCameraSchema[];
interface LiveStreamSchema {
	_id: string;
	title: string;
	status: string;
	channels: PairSchema;
}

export type broadcastDetailSchema = broadcastSchema[];
export type brodRecordingSchema = recordingSchema[];

interface BroadCastInterface {
	dDName: string;
	vodURL: string;
	broadcastLive: LiveStatuses[];
	bucketNames: string[];

	addBroadcastDetailLoading: boolean;
	addBroadcastDetailMessage: string;
	addBroadcastDetailError: string;

	broadcastDetailLoading: boolean;
	broadcastDetail: broadcastDetailSchema;
	broadcastDetailError: string;

	broadRecordings: brodRecordingSchema;

	deleteBroadcastLoading: boolean;
	deleteBroadcastMessage: string;
	deleteBroadcastError: string;

	getOneBroadcastLoading: boolean;
	getOneBroadcast: BroadcastNewSchema | null;
	getOneBroadcastError: string;

	updateBroadcastLoading: boolean;
	updateBroadcastMessage: string;
	updateBroadcastError: string;

	broadcastLiveStreamLoading: boolean;
	broadcastLiveStream: LiveStreamSchema | null;
	broadcastLiveStreamError: string;

	endStreamLoading: boolean;
	endStreamMessage: string;
	endStreamErrorMessage: string;
}

const initialState: BroadCastInterface = {
	dDName: "",
	vodURL: "",
	broadcastLive: [],
	bucketNames: [],

	addBroadcastDetailLoading: false,
	addBroadcastDetailMessage: "",
	addBroadcastDetailError: "",

	broadcastDetailLoading: true,
	broadcastDetail: [],
	broadcastDetailError: "",

	broadRecordings: [],

	deleteBroadcastLoading: false,
	deleteBroadcastMessage: "",
	deleteBroadcastError: "",

	getOneBroadcastLoading: false,
	getOneBroadcast: null,
	getOneBroadcastError: "",

	updateBroadcastLoading: false,
	updateBroadcastMessage: "",
	updateBroadcastError: "",

	broadcastLiveStreamLoading: false,
	broadcastLiveStream: null,
	broadcastLiveStreamError: "",

	endStreamLoading: false,
	endStreamMessage: "",
	endStreamErrorMessage: "",
};

export const broadcastSlice = createSlice({
	name: "broadcast",
	initialState,
	reducers: {
		// set distributionDomainName
		setdistributionDomainName: (state, action) => {
			state.dDName = action.payload;
		},

		// set VodURL
		setVideoOnDemandUrl: (state, action) => {
			state.vodURL = action.payload;
		},

		// set BucketNames
		setbucketNames: (state, action) => {
			state.bucketNames = action.payload;
		},

		// set Broadcast Channel Live
		setBroadcastChannelLive: (state, action) => {
			state.broadcastLive = action.payload;
		},

		// add broadcast Data
		addBroadcastRequest: (state) => {
			state.addBroadcastDetailLoading = true;
			state.addBroadcastDetailError = "";
		},
		addBroadcastSuccess: (state, action) => {
			state.addBroadcastDetailLoading = false;
			state.addBroadcastDetailMessage = action.payload;
		},
		addBroadcastError: (state, action) => {
			state.addBroadcastDetailLoading = false;
			state.addBroadcastDetailError = action.payload;
		},

		// broadcast Data
		broadcastRequest: (state) => {
			state.broadcastDetailLoading = true;
			state.broadcastDetailError = "";
		},
		broadcastSuccess: (state, action) => {
			state.broadcastDetailLoading = false;
			state.broadcastDetail = action.payload;
		},
		broadcastError: (state, action) => {
			state.broadcastDetailLoading = false;
			state.broadcastDetailError = action.payload;
		},

		// recordings 
		broadcastRecordings: (state, action) => {
			state.broadRecordings = action.payload;
		},

		//delete broadcast Data
		deleteBroadcastRequest: (state) => {
			state.deleteBroadcastLoading = true;
			state.deleteBroadcastError = "";
		},
		deleteBroadcastSuccess: (state, action) => {
			state.deleteBroadcastLoading = false;
			state.deleteBroadcastMessage = action.payload;
		},
		deleteBroadcastError: (state, action) => {
			state.deleteBroadcastLoading = false;
			state.deleteBroadcastError = action.payload;
		},

		//get one broadcast Data
		getOneBroadcastRequest: (state) => {
			state.getOneBroadcastLoading = true;
			state.getOneBroadcastError = "";
		},
		getOneBroadcastSuccess: (state, action) => {
			state.getOneBroadcastLoading = false;
			state.getOneBroadcast = action.payload;
		},
		getOneBroadcastError: (state, action) => {
			state.getOneBroadcastLoading = false;
			state.getOneBroadcastError = action.payload;
		},

		//update broadcast Data
		updateBroadcastRequest: (state) => {
			state.updateBroadcastLoading = true;
			state.updateBroadcastError = "";
		},
		updateBroadcastSuccess: (state, action) => {
			state.updateBroadcastLoading = false;
			state.updateBroadcastMessage = action.payload;
		},
		updateBroadcastError: (state, action) => {
			state.updateBroadcastLoading = false;
			state.updateBroadcastError = action.payload;
		},

		//broadCastLive stream Data
		broadcastLiveStreamRequest: (state) => {
			state.broadcastLiveStreamLoading = true;
			state.broadcastLiveStreamError = "";
		},
		broadcastLiveStreamSuccess: (state, action) => {
			state.broadcastLiveStreamLoading = false;
			state.broadcastLiveStream = action.payload;
		},
		broadcastLiveStreamError: (state, action) => {
			state.broadcastLiveStreamLoading = false;
			state.broadcastLiveStreamError = action.payload;
		},

		//end live broadcast stream Data
		endLiveStreamRequest: (state) => {
			state.endStreamLoading = true;
			state.endStreamErrorMessage = "";
		},
		endLiveStreamSuccess: (state, action) => {
			state.endStreamLoading = false;
			state.endStreamMessage = action.payload;
		},
		endLiveStreamError: (state, action) => {
			state.endStreamLoading = false;
			state.endStreamErrorMessage = action.payload;
		},

		resetEndStreamMessage: (state) => {
			state.endStreamErrorMessage = "";
			state.endStreamMessage = "";
		},

		resetBroadcastMessage: (state) => {
			state.addBroadcastDetailMessage = "";
			state.addBroadcastDetailError = "";
			state.deleteBroadcastError = "";
			state.deleteBroadcastMessage = "";
			state.getOneBroadcastError = "";
			state.getOneBroadcast = null;
			state.updateBroadcastError = "";
			state.updateBroadcastMessage = "";
		},
	},
});

export const {
	setdistributionDomainName,
	setVideoOnDemandUrl,
	setBroadcastChannelLive,
	setbucketNames,

	addBroadcastRequest,
	addBroadcastSuccess,
	addBroadcastError,

	broadcastRequest,
	broadcastSuccess,
	broadcastError,

	broadcastRecordings,

	deleteBroadcastRequest,
	deleteBroadcastSuccess,
	deleteBroadcastError,

	getOneBroadcastRequest,
	getOneBroadcastSuccess,
	getOneBroadcastError,

	updateBroadcastRequest,
	updateBroadcastSuccess,
	updateBroadcastError,

	broadcastLiveStreamRequest,
	broadcastLiveStreamSuccess,
	broadcastLiveStreamError,

	endLiveStreamRequest,
	endLiveStreamSuccess,
	endLiveStreamError,
	resetEndStreamMessage,

	resetBroadcastMessage,
} = broadcastSlice.actions;

export default broadcastSlice.reducer;
