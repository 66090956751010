import { createSlice } from "@reduxjs/toolkit";
import { WifiSettingInterface } from "../pages/Devices";

interface CameraSchema {
  camera1: string;
  camera2: string;
}

interface SettingSchema {
  output_watchdog_timeout: number;
  default_bitrate: number;
  skip_audio_value: number;
  video_duration: number;
  video_output: string;
}

interface Person {
  ngrockId: string;
  doResetWifi: boolean;
  camera: CameraSchema;
  deviceCode: string;
  deviceName: string;
  deviceType: string;
  enable_ssh: boolean;
  ssh_address: string;
  is_reserve: boolean;
  serial: string;
  settings: SettingSchema;
  _id: string;
  userId: string;
  createdAt: string;
  status: boolean;
  wifi_settings: WifiSettingInterface;
}

export type Workplace = Person[];

interface DevicesInterface {
  deviceDataLoading: boolean;
  deviceData: Workplace;
  deviceDataError: string;

  unReservedDeviceLoading: boolean;
  unReservedDevice: Workplace;
  unReservedDeviceError: string;

  addDeviceDataLoading: boolean;
  addDeviceDataMsg: string;
  addDeviceDataError: string;

  deleteDeviceLoading: boolean;
  deleteDeviceMessage: string;
  deleteDeviceError: string;

  getOneDeviceLoading: boolean;
  getOneDevice: Person | null;
  getOneDeviceError: string;

  updateDeviceLoading: boolean;
  updateDeviceMessage: string;
  updateDeviceError: string;
}

const initialState: DevicesInterface = {
  deviceDataLoading: true,
  deviceData: [],
  deviceDataError: "",

  unReservedDeviceLoading: false,
  unReservedDevice: [],
  unReservedDeviceError: "",

  addDeviceDataLoading: false,
  addDeviceDataMsg: "",
  addDeviceDataError: "",

  deleteDeviceLoading: false,
  deleteDeviceMessage: "",
  deleteDeviceError: "",

  getOneDeviceLoading: false,
  getOneDevice: null,
  getOneDeviceError: "",

  updateDeviceLoading: false,
  updateDeviceMessage: "",
  updateDeviceError: "",
};

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    // Get Device Data
    getDeviceDataRequest: (state) => {
      state.deviceDataLoading = true;
      state.deviceDataError = "";
    },
    getDeviceDataSuccess: (state, action) => {
      state.deviceDataLoading = false;
      state.deviceData = action.payload;
    },
    getDeviceDataFailed: (state, action) => {
      state.deviceDataLoading = false;
      state.deviceDataError = action.payload;
    },
    // Add Device Data
    addDeviceDataRequest: (state) => {
      state.addDeviceDataLoading = true;
      state.addDeviceDataMsg = "";
      state.addDeviceDataError = "";
    },
    addDeviceDataSuccess: (state, action) => {
      state.addDeviceDataLoading = false;
      state.addDeviceDataMsg = action.payload.message;
      state.addDeviceDataError = "";
    },
    addDeviceDataFailed: (state, action) => {
      state.addDeviceDataLoading = false;
      state.addDeviceDataMsg = "";
      state.addDeviceDataError = action.payload;
    },

    // Delete Device Data
    deleteDeviceRequest: (state) => {
      state.deleteDeviceLoading = true;
      state.deleteDeviceError = "";
    },
    deleteDeviceSuccess: (state, action) => {
      state.deleteDeviceMessage = action.payload;
      state.deleteDeviceLoading = false;
    },
    deleteDeviceError: (state, action) => {
      state.deleteDeviceError = action.payload;
      state.deleteDeviceLoading = false;
    },
    resetDeleteMessage: (state) => {
      state.deleteDeviceMessage = "";
      state.deleteDeviceError = "";
    },

    // getOne Device Data
    getOneDeviceRequest: (state) => {
      state.getOneDeviceLoading = true;
      state.getOneDeviceError = "";
    },
    getOneDeviceSuccess: (state, action) => {
      state.getOneDevice = action.payload;
      state.getOneDeviceLoading = false;
    },
    getOneDeviceError: (state, action) => {
      state.getOneDeviceError = action.payload;
      state.getOneDeviceLoading = false;
    },

    // update Device Data
    updateDeviceRequest: (state) => {
      state.updateDeviceLoading = true;
      state.updateDeviceError = "";
    },
    updateDeviceSuccess: (state, action) => {
      state.updateDeviceMessage = action.payload;
      state.updateDeviceLoading = false;
    },
    updateDeviceError: (state, action) => {
      state.updateDeviceError = action.payload;
      state.updateDeviceLoading = false;
    },

    // un-reserved Data
    unReservedDeviceRequest: (state) => {
      state.unReservedDeviceLoading = true;
      state.unReservedDeviceError = "";
    },
    unReservedDeviceSuccess: (state, action) => {
      state.unReservedDevice = action.payload;
      state.unReservedDeviceLoading = false;
    },
    unReservedDeviceError: (state, action) => {
      state.unReservedDeviceError = action.payload;
      state.unReservedDeviceLoading = false;
    },

    resetEditDeviceMessage: (state) => {
      state.getOneDevice = null;
      state.getOneDeviceError = "";
      state.updateDeviceMessage = "";
      state.updateDeviceError = "";
    },

    resetDeviceMessage: (state) => {
      state.addDeviceDataError = "";
      state.addDeviceDataMsg = "";
      state.deviceDataError = "";
    },
  },
});

export const {
  getDeviceDataRequest,
  getDeviceDataSuccess,
  getDeviceDataFailed,
  addDeviceDataRequest,
  addDeviceDataSuccess,
  addDeviceDataFailed,
  resetDeviceMessage,

  deleteDeviceRequest,
  deleteDeviceSuccess,
  deleteDeviceError,
  resetDeleteMessage,

  getOneDeviceRequest,
  getOneDeviceSuccess,
  getOneDeviceError,

  updateDeviceRequest,
  updateDeviceSuccess,
  updateDeviceError,

  unReservedDeviceRequest,
  unReservedDeviceSuccess,
  unReservedDeviceError,

  resetEditDeviceMessage,
} = deviceSlice.actions;

export default deviceSlice.reducer;
