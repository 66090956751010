import { delPayload, get, post, put } from "../../../helper";

const URI = "/device";

const getDeviceData = (user_id: string) => {
  return get(`${URI}/listbyuser/${user_id}`);
};

const addDeviceData = (payload: object) => {
  return post(`${URI}/add`, payload);
};

const deleteDeviceData = (payload: object) => {
  return delPayload(`${URI}/delete`, payload);
};

const getOneDeviceData = (device_id: string) => {
  return get(`${URI}/list/${device_id}`);
};

const getUpdateDeviceData = (device_id: string, payload: object) => {
  return put(`${URI}/update/${device_id}`, payload);
};

const getUnReservedDevice = () => {
  return get(`${URI}/reserve`);
};

const DeviceService = {
  getDeviceData,
  addDeviceData,
  deleteDeviceData,
  getOneDeviceData,
  getUpdateDeviceData,
  getUnReservedDevice,
};
export default DeviceService;
