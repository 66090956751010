import React from "react";
import Layout from "../layout/Layout";
import LayoutUpdate from "../layout/LayoutUpdate";

function Home({ setThemeMode, themeMode }: any) {
  return (
    <div>
      {/* <Layout setThemeMode={setThemeMode} themeMode={themeMode} /> */}
      <LayoutUpdate setThemeMode={setThemeMode} themeMode={themeMode} />
    </div>
  );
}

export default Home;
